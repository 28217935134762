import { estadisticasSlice } from "./_reducers";

const actions = estadisticasSlice.actions;

export const {
  setViewPregunta,
  setFinishPregunta,
  setBackCount,
  setNextCount,
  setSkipCount,
  setFinishCount,
  cleanEstadisticas
} = actions;
