import React from "react";

const QuestionLabel = ({ question }: Props) => {
  return (
    <p>
      <strong>{question}</strong>
    </p>
  );
};

interface Props {
  question: string;
}

export default QuestionLabel;
