import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchNacionalidad } from "./_fetchers";
import { INITIAL_STATE, Nacionalidad } from "./_namespace";

export const nacionalidadSlice = createSlice({
  name: "nacionalidad",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchNacionalidad.fulfilled,
      (state, action: PayloadAction<Nacionalidad[]>) => {
        state.fetching = false;
        state.nacionalidades = action.payload;
      }
    );
  }
});
