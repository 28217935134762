import React from "react";
import "./index.scss";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdError } from "react-icons/md";

type Props = { error?: boolean; color?: string } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const IconText: React.FC<Props> = (props) => {
  const { children, error, color, ...rest } = props;
  return (
    <div
      className="icon-text-ecu"
      {...rest}
    >
      {error ? (
        <div className={`${color}-icon`}>
          <MdError />
        </div>
      ) : (
        <div className={`${color}-icon`}>
          <AiFillCheckCircle />
        </div>
      )}

      <div>{children}</div>
    </div>
  );
};

export default IconText;
