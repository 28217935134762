import React from "react";
import { NSQuestion } from "../../redux/slices/questions";
import image from "../../assets/lupa2.png";
import { useAppDispatch } from "../../redux/store";
import { open, setContent } from "../../redux/slices/modal";
import DisplayImageModal from "../DisplayImageModal";

const OptionImage = ({ option }: Props) => {
  const { imagen, texto } = option;
  const basePath = process.env.REACT_APP_BACK_MULTIMEDIA;
  const srcImage = basePath ? basePath + imagen : "";
  const dispatch = useAppDispatch();

  const handleClick = (srcImage: string) => {
    dispatch(setContent(<DisplayImageModal image={srcImage} />));
    dispatch(open());
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center tooltipImage"
      style={{ position: "relative" }}
    >
      <span
        className="tooltiptext"
        style={{ width: "100%" }}
      >
        Da click sobre la lupa a la derecha de la imágen para aumentar su tamaño
      </span>
      {srcImage && (
        <img
          alt={texto}
          className="imagen-opcion"
          src={srcImage}
          style={{ maxWidth: 200 }}
        />
      )}
      <div
        className="lupa"
        style={{
          backgroundColor: "#026ca1",
          paddingBottom: 4,
          paddingLeft: 12,
          borderBottomLeftRadius: 60
        }}
        onClick={() => handleClick(srcImage)}
      >
        <img
          alt="lupa icon"
          height={30}
          src={image}
          width={30}
        />
      </div>
    </div>
  );
};

interface Props {
  option: NSQuestion.STATE["preguntas"][number]["opciones"][number];
}

export default OptionImage;
