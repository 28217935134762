import { useEffect, useState } from "react";
import { useAppSelector } from "../redux/store";
import {
  NSQuestion,
  Pregunta,
  questionSelector
} from "../redux/slices/questions";
import { getPreguntaByID, sortPreguntasById } from "../utils/preguntas";
import { getAnswerType } from "../utils/tiposRespuesta";
import { isFirstQuestion } from "../utils/preguntas";
import { isLastQuestion } from "../utils/preguntas";

export const usePreguntas = (questionId: string) => {
  const [pregunta, setPregunta] = useState<Pregunta>();
  const [tipoRespuesta, setTipoRespuesta] = useState<string>();
  const [isFirst, setIsFirst] = useState<boolean>(false);
  const [isLast, setIsLast] = useState<boolean>(false);
  const [preguntasSorted, setPreguntasSorted] = useState<Pregunta[]>([]);

  const { preguntas } = useAppSelector(questionSelector) as NSQuestion.STATE;

  useEffect(() => {
    if (preguntas.length) {
      const questions = sortPreguntasById([...preguntas]);
      setPreguntasSorted(questions);
      const question = getPreguntaByID(questions, questionId);
      setPregunta(question);
      if (question) {
        setTipoRespuesta(getAnswerType(question));
      }
      setIsFirst(isFirstQuestion(questions, questionId));
      setIsLast(isLastQuestion(questions, questionId));
    }
  }, [questionId, preguntas]);

  return { pregunta, tipoRespuesta, isFirst, isLast, preguntasSorted };
};
