import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchResultado } from "./_fetchers";
import { INITIAL_STATE, Resultado } from "./_namespace";

export const resultadoSlice = createSlice({
  name: "resultado",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchResultado.fulfilled,
      (state, action: PayloadAction<Resultado>) => {
        state.fetching = false;
        state.resultado = action.payload;
      }
    );
    builder.addCase(fetchResultado.pending, (state) => {
      state.fetching = true;
    });
  }
});
