export declare namespace NSLocalidad {
  type STATE = { fetching: boolean; localidades: Localidad[] };
}

export type Localidad = {
  punto_encuentro: string;
  _id: string;
};

export const INITIAL_STATE: NSLocalidad.STATE = {
  fetching: false,
  localidades: []
};
