import SimulacionGif from "../../assets/gifs/simulacion.gif";

export const SimulacionModal = () => {
  return (
    <div className="container mb-5">
      <h3 className="text-center">Ejercicio Práctico</h3>
      <section className="text-center mt-4">
        <p>
          En esta pregunta se muestra una página externa donde debes interactuar
          para encontrar la respuesta
          <br />
          Si tu conexión a <strong>internet no es estable</strong> y no se
          muestra el componente correctamente,{" "}
          <strong>por favor recarga la página</strong>
        </p>
      </section>
      <section className="d-flex align-items-center justify-content-center">
        <img
          alt="Simulacion gif"
          height="auto"
          src={SimulacionGif}
          width="50%"
        />
      </section>
    </div>
  );
};
