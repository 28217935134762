import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchConfiguration } from "./_fetchers";
import { INITIAL_STATE, Configuration } from "./_namespace";

export const configurationSlice = createSlice({
  name: "etnia",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchConfiguration.fulfilled,
      (state, action: PayloadAction<Configuration[]>) => {
        state.fetching = false;
        state.configuration = action.payload;
      }
    );
    builder.addCase(fetchConfiguration.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchConfiguration.rejected, (state) => {
      state.fetching = false;
      state.configuration = [];
    });
  }
});
