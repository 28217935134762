import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
export const generoSelector = createSelector(
  (state: RootState) => state.genero,
  (state) => {
    if (state) {
      return state;
    }
    return false;
  }
);
