import React from "react";
import { setOption, Pregunta } from "../../../redux/slices/questions";
import { useAppDispatch } from "../../../redux/store";
import "./index.scss";
import Option from "../../../components/Option";

interface Props {
  pregunta: Pregunta;
}

const OptionList = ({ pregunta }: Props) => {
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idAnswer = event.target.value;
    dispatch(setOption({ [pregunta._id]: idAnswer }));
  };

  return (
    <div className="my-5">
      <div className="row">
        {pregunta &&
          pregunta.opciones?.map((option, index) => (
            <Option
              key={index}
              option={option}
              question={pregunta}
              onChange={handleChange}
            />
          ))}
      </div>
    </div>
  );
};

export default OptionList;
