import React from "react";
import QuestionHeader from "../../modules/Pregunta/Header";
import { useAppDispatch } from "../../redux/store";
import { close } from "../../redux/slices/modal";
import { useAppSelector } from "../../redux/store";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import QuestionContainer from "../../modules/Pregunta";

const Question = () => {
  const dispatch = useAppDispatch();
  const { active, area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const navigate = useNavigate();
  const { id = "" } = useParams();

  React.useEffect(() => {
    if (!area) {
      navigate("/");
    } else if (!active) {
      navigate("/?status=0");
    }
    return () => {
      dispatch(close());
    };
  }, [active, area, dispatch, navigate]);

  return (
    <div className="main-container">
      <QuestionHeader />
      <QuestionContainer questionId={id} />
    </div>
  );
};

export default Question;
