export declare namespace NSEtnia {
  type STATE = {
    etnias: Etnia[];
    fetching: boolean;
  };
}

export type Etnia = {
  _id: string;
  etnia: string;
};

export const INITIAL_STATE: NSEtnia.STATE = {
  etnias: [],
  fetching: false
};
