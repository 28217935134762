import React from "react";
import image from "../../assets/intro.png";
import Button from "../Button";
import "./index.scss";
import { scroll } from "../../utils";

const Intro = () => {
  return (
    <div className="ecua-intro">
      <div className="ecua-intro-container">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <div>
                <h1 className="intro-titulo mb-3">
                  ¡Evalúa tus Competencias Digitales!
                </h1>
                <p className="intro-subtitulo mb-3">
                  Conocer tus capacidades con la tecnología te ayudará a mejorar
                  tus oportunidades laborales y educativas
                </p>
                <div>
                  <Button
                    className="boton-evaluate"
                    color="blue"
                    //textColor="white"
                    radius={5}
                    onClick={() => {
                      scroll("#competence-test");
                    }}
                  >
                    <span className="text-intro-boton">Evalúate ya</span>
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center justify-content-center">
              <img
                alt="imagen"
                className="img-fluid"
                src={image}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <svg
        viewBox="0 0 1440 280"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="ecua-wave"
          d="M0 380.334L107.2 407.5C212.8 434.667 427.2 489 640 489C852.8 489 1067.2 434.667 1280 425.611C1492.8 416.556 1707.2 452.778 1812.8 470.889L1920 489L1920 0.000213623H1812.8C1707.2 0.000213623 1492.8 0.000213623 1280 0.000213623C1067.2 0.000213623 852.8 0.000213623 640 0.000213623C427.2 0.000213623 212.8 0.000213623 107.2 0.000213623L0 0.000213623L0 380.334ZZ"
        ></path>
        <path
          className="ecua-wave-2"
          d="M0 286.071L107.058 300.074C212.707 314.078 426.823 341.084 639.53 361.089C853.646 382.095 1066.35 395.098 1280.47 361.089C1493.18 327.081 1707.29 245.061 1812.94 204.05L1920 164.041V-4.51939e-07H1812.94C1707.29 -4.51939e-07 1493.18 -4.51939e-07 1280.47 -4.51939e-07C1066.35 -4.51939e-07 853.646 -4.51939e-07 639.53 -4.51939e-07C426.823 -4.51939e-07 212.707 -4.51939e-07 107.058 -4.51939e-07H0L0 286.071Z"
        ></path>
      </svg>
    </div>
  );
};

export default Intro;
