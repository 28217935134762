import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";

export const fetchNacionalidad = createAsyncThunk(
  "nacionalidad/getNacionalidad",
  async () => {
    const response = await mintelapi.get("/nacionalidad");
    return response.data;
  }
);
