import { Persona } from "../persona";

export declare namespace NSResultado {
  type STATE = {
    fetching: boolean;
    resultado: Resultado | undefined;
  };
}

export interface Resultado {
  promedio: number;
  puntaje: number;
  nivel: number;
  competencias: CompetenciaEvaluada[];
  persona: Partial<Persona>;
  codigo: number;
}

export interface CompetenciaEvaluada {
  competencia: string;
  descripcion: string;
  codigo: number;
  cursos: Curso[];
  puntaje: number;
  nivel: number;
}

export interface Curso {
  link: string;
  imagen: string;
  nivel: number;
}

export const INITIAL_STATE: NSResultado.STATE = {
  fetching: false,
  resultado: undefined
};
