import React from "react";
import Button from "../../../../components/Button";
import { handleStepsAction } from "../../utils";
import { submitEncuesta } from "../../services/submit";

const EncuestaFooter = ({
  indexQuestion,
  totalPreguntas,
  idEncuesta,
  respuestas,
  dispatch,
  setIndexQuestion
}: Props) => {
  const handleNext = () => {
    if (indexQuestion < totalPreguntas - 1) {
      setIndexQuestion(indexQuestion + 1);
    }
  };

  const handleFinish = async () => {
    await submitEncuesta(idEncuesta, respuestas);
    dispatch({ type: handleStepsAction.thirdStep });
  };

  const isFinal = () => indexQuestion === totalPreguntas - 1;

  return (
    <div
      className="bottom-button"
      style={{ display: "flex", gap: "8px" }}
    >
      {isFinal() ? (
        <Button
          color="green"
          radius={20}
          textColor="white"
          onClick={handleFinish}
        >
          Enviar
        </Button>
      ) : (
        <Button
          color="blue"
          radius={20}
          textColor="white"
          onClick={handleNext}
        >
          Siguiente
        </Button>
      )}
    </div>
  );
};

interface Props {
  indexQuestion: number;
  totalPreguntas: number;
  idEncuesta: string;
  respuestas: Record<string, string[]>;
  dispatch: (value: any) => void;
  setIndexQuestion: (value: any) => void;
}

export default EncuestaFooter;
