import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";

export const fetchInstituciones = createAsyncThunk(
  "instituciones/getAllActives",
  async () => {
    const response = await mintelapi.get("/instituciones/getAllActives");
    return response.data;
  }
);
