import { useNavigate } from "react-router-dom";
import { open, setContent } from "../../../redux/slices/modal";
import { NSQuestion, Pregunta } from "../../../redux/slices/questions";
import { useAppDispatch } from "../../../redux/store";
import { getNextID } from "../../../utils/preguntas";
import {
  setFinishPregunta,
  setSkipCount,
  setViewPregunta
} from "../../../redux/slices/estadisticas/_actions";
import { WarningModal } from "../../../components/WarningModal";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Button";

interface SkipButtonInterface {
  preguntaId: string;
  isLastQuestion: boolean;
  respuestas: NSQuestion.Answers;
  preguntas: Pregunta[];
}

export const SkipButton = ({
  isLastQuestion,
  preguntaId,
  respuestas,
  preguntas
}: SkipButtonInterface) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const handleFinished = () => {
    const remaining = preguntas.length - Object.entries(respuestas).length;
    if (remaining) {
      dispatch(
        setContent(<WarningModal questionsWithoutAnswers={remaining} />)
      );
      dispatch(open());
    } else {
      dispatch(setContent(<FormModal />));
      dispatch(open());
    }
  };

  const handleSkip = () => {
    if (!isLastQuestion) {
      const id = getNextID(preguntas, preguntaId);
      navigate(`/evaluacion/${id}`);
      dispatch(setFinishPregunta(preguntaId));
      dispatch(setSkipCount(preguntaId));
      dispatch(setViewPregunta(id));
    } else {
      handleFinished();
    }
  };

  return (
    <Button
      color="red"
      id="skip-button"
      radius={15}
      textColor="white"
      onClick={handleSkip}
    >
      Saltar
    </Button>
  );
};
