export declare namespace NSModal {
  type STATE = {
    open: boolean;
    content?: undefined | JSX.Element;
  };
}

export const INITIAL_STATE = {
  open: false,
  content: undefined
} as NSModal.STATE;
