import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";
import { NSPersona } from "./_namespace";

export const fetchPersona = createAsyncThunk(
  "persona/getPersona",
  async (id: NSPersona.ID) => {
    const response = await mintelapi.get(`/persona/${id}`);
    return response.data;
  }
);
