import { Swiper } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "./index.scss";
import { Autoplay, Navigation } from "swiper";
interface Props {
  children: any;
}

const Carousel = ({ children }: Props) => {
  return (
    <div className="my-5">
      <Swiper
        autoplay={{
          delay: 4500,
          disableOnInteraction: false
        }}
        centeredSlides={true}
        className="mySwiper"
        modules={[Autoplay, Navigation]}
        navigation={true}
        spaceBetween={30}
      >
        {children}
      </Swiper>
    </div>
  );
};

export default Carousel;
