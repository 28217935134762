import { ErrorMessage } from "formik";
import React from "react";
import { FieldError } from "../FieldError";

import "./index.scss";

type Props = {
  label?: string;
  errors?: string;
  requiredLabel?: boolean;
  name: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  React.AriaAttributes;

const Input: React.FC<Props> = (props) => {
  const { label, errors, value, requiredLabel, name, ...rest } = props;
  return (
    <>
      <label className={requiredLabel ? "required-label" : ""}>{label}</label>
      <input
        className="form-control"
        name={name}
        value={value}
        {...rest}
      />
      <ErrorMessage
        name={name}
        render={FieldError}
      />
    </>
  );
};

export default Input;
