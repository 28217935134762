import { ErrorMessage } from "formik";
import React from "react";
import { FieldError } from "../FieldError";
import "./index.scss";

type Props = {
  label?: string;
  errors?: string;
  requiredLabel?: boolean;
  name: string;
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> &
  React.AriaAttributes;

const Select: React.FC<Props> = (props) => {
  const { label, errors, value, requiredLabel, children, name, ...rest } =
    props;
  return (
    <>
      <label className={requiredLabel ? "required-label" : ""}>{label}</label>
      <select
        className="form-control"
        name={name}
        {...rest}
      >
        {children}
      </select>
      <ErrorMessage
        name={name}
        render={FieldError}
      />
    </>
  );
};

export default Select;
