import mintelapi from "../../mintelapi";

export const getResultados = async (id: string) => {
  const response = await mintelapi.get(`/respuestas/${id}`);
  const competencias = response.data.competencias.map((e: any) => {
    const descripcionNivel = e.competencia.descripciones?.find(
      (desc: any) => desc?.nivel === e?.nivel
    );
    return {
      descripcion: descripcionNivel?.descripcion,
      competencia: e.competencia.competencia,
      codigo: e.competencia.codigo,
      cursos: e.competencia.cursos,
      nivel: e.nivel,
      puntaje: e.puntaje
    };
  });
  return { ...response.data, competencias };
};
