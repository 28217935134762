import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPersona } from "./_fetchers";
import { INITIAL_STATE, Persona } from "./_namespace";

export const personaSlice = createSlice({
  name: "persona",
  initialState: INITIAL_STATE,
  reducers: {
    clean(state) {
      state.fetching = false;
      state.persona = undefined;
    }
  },
  extraReducers(builder) {
    builder.addCase(
      fetchPersona.fulfilled,
      (state, action: PayloadAction<Persona>) => {
        state.fetching = false;
        state.persona = action.payload;
      }
    );
    builder.addCase(fetchPersona.pending, (state) => {
      state.fetching = true;
    });
  }
});
