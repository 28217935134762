import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGeneros } from "./_fetchers";
import { INITIAL_STATE, Genero } from "./_namespace";

export const generoSlice = createSlice({
  name: "genero",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchGeneros.fulfilled,
      (state, action: PayloadAction<Genero[]>) => {
        state.fetching = false;
        state.generos = action.payload;
      }
    );
  }
});
