import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./_namespace";

export const estadisticasSlice = createSlice({
  name: "esadisticas",
  initialState: INITIAL_STATE,
  reducers: {
    cleanEstadisticas(state) {
      state.finish_count = INITIAL_STATE.finish_count;
      state.estadisticasPreguntas = INITIAL_STATE.estadisticasPreguntas;
    },
    setViewPregunta(state, action: PayloadAction<string>) {
      const questionId = action.payload;
      const questionPayload = state.estadisticasPreguntas[questionId];
      if (questionPayload) {
        state.estadisticasPreguntas[questionId].vistas =
          state.estadisticasPreguntas[questionId].vistas + 1;
        state.estadisticasPreguntas[questionId].timestamps.push({
          start: new Date(Date.now()),
          end: undefined
        });
      } else {
        state.estadisticasPreguntas[questionId] = {
          vistas: 1,
          atras: 0,
          siguiente: 0,
          saltar: 0,
          duracion: [],
          timestamps: [{ start: new Date(Date.now()), end: undefined }]
        };
      }
    },
    setFinishPregunta(state, action: PayloadAction<string>) {
      const endDate = new Date(Date.now());
      const questionId = action.payload;
      const estadisticasPregunta = state.estadisticasPreguntas[questionId];

      const timestampsLength = estadisticasPregunta?.timestamps.length;
      const timestapmsLastPosition = timestampsLength - 1;
      if (!timestampsLength) return;

      state.estadisticasPreguntas[questionId].timestamps[
        timestapmsLastPosition
      ].end = endDate;
      const { start: startDate } =
        estadisticasPregunta.timestamps[timestapmsLastPosition];

      const seconds =
        ((endDate.getTime() || 0) - (startDate?.getTime() || 0)) / 1000;
      state.estadisticasPreguntas[questionId].duracion.push(seconds);
    },
    setBackCount(state, action: PayloadAction<string>) {
      const questionId = action.payload;
      const questionPayload = state.estadisticasPreguntas[questionId];
      if (questionPayload) {
        state.estadisticasPreguntas[questionId].atras++;
      }
    },
    setNextCount(state, action: PayloadAction<string>) {
      const questionId = action.payload;
      const questionPayload = state.estadisticasPreguntas[questionId];
      if (questionPayload) {
        state.estadisticasPreguntas[questionId].siguiente++;
      }
    },
    setSkipCount(state, action: PayloadAction<string>) {
      const questionId = action.payload;
      const questionPayload = state.estadisticasPreguntas[questionId];
      if (questionPayload) {
        state.estadisticasPreguntas[questionId].saltar++;
      }
    },
    setFinishCount(state) {
      state.finish_count++;
    }
  }
});
