import React from "react";
import Button from "../Button";
import cards from "./cardjson";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { close, open, setContent } from "../../redux/slices/modal";
import {
  NSQuestion,
  questionSelector,
  setStartTest
} from "../../redux/slices/questions";
import { FiClock } from "react-icons/fi";
import { sortPreguntasById } from "../../utils/preguntas";
import { setViewPregunta } from "../../redux/slices/estadisticas/_actions";
import Encuesta from "../../modules/Encuesta";
import {
  NSEncuestaMode,
  NSEncuestaSTATE,
  encuestaSelector
} from "../../redux/slices/encuesta";

type ModalContentProps = {
  card: typeof cards[number];
};

const ModalContent: React.FC<ModalContentProps> = (props) => {
  const { title, fullDescription, competences, modalsrc } = props.card;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { encuesta } = useAppSelector(encuestaSelector) as NSEncuestaSTATE;
  const { preguntas } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const questions = sortPreguntasById([...(preguntas || [])]);

  const handleClick = () => {
    dispatch(close());

    dispatch(setStartTest(new Date()));
    const questionId = questions?.[0]?._id;
    dispatch(setViewPregunta(questionId));

    if (encuesta.mode === NSEncuestaMode.Before) {
      dispatch(setContent(<Encuesta nextIdQuestion={questionId} />));
      dispatch(open());
    } else {
      navigate(`/evaluacion/${questionId}`);
    }
  };

  return (
    <div className="container">
      <div className="row mb-3">
        <div className="col-xl-4">
          <div className="d-flex align-items-center justify-content-center">
            <img
              alt={title}
              className="img-fluid"
              src={modalsrc}
            />
          </div>
        </div>

        <div className="col-xl-8">
          <div className="row">
            <div className="col-xl-3">
              <h1 className="text-center">Área:</h1>
            </div>
            <div className="col-xl-9">
              <p className="text">{title}</p>
            </div>
          </div>
          <h5>
            <strong>
              <FiClock /> Tiempo aproximado: 15 minutos.
            </strong>
          </h5>
          <p>{fullDescription}</p>
          <h2>Competencias a evaluar</h2>
          <ul>
            {competences.map((value) => {
              return <li key={value}>{value}</li>;
            })}
          </ul>
        </div>
      </div>
      <div className="mt-2 mb-5 position-relative">
        <div className="d-flex align-items-center justify-content-center">
          <Button
            color="blue"
            radius={7}
            textColor="white"
            onClick={handleClick}
          >
            Comenzar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModalContent;
