export declare namespace NSInstituciones {
  type STATE = {
    fetching: boolean;
    instituciones: institucion[];
  };
}

export type institucion = {
  name: string;
  _id: string;
};

export const INITIAL_STATE: NSInstituciones.STATE = {
  fetching: false,
  instituciones: []
};
