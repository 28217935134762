import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NSQuestion, questionSelector } from "../../redux/slices/questions";
import { useAppSelector } from "../../redux/store";
import { CANTIDAD_PREGUNTAS } from "../../utils/constants";
import "./index.scss";
import { getIndexPreguntaById, sortPreguntasById } from "../../utils/preguntas";

const TOTAL = 100;
const RADIUS = "20px";
const PROGRESS_STATES = {
  IN_PROGRESS: "#ffff00",
  PENDING: "#ff0000",
  NOT_SHOWED: "#f1f1f1",
  ALREADY_ANSWERED: "#ffff00"
};

interface ProgressBarProps {
  complete?: boolean;
}

export const ProgressBar = (props: ProgressBarProps) => {
  const { complete } = props;
  const { respuestas, preguntas: questions } = useAppSelector(
    questionSelector
  ) as NSQuestion.STATE;

  const { id = "" } = useParams();
  const totalQuestions = questions.length;
  const preguntas = sortPreguntasById([...questions]);
  const indexPregunta = getIndexPreguntaById(preguntas, id);

  const [progress, setProgress] = React.useState(TOTAL / CANTIDAD_PREGUNTAS);
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(`/evaluacion/${id}`);
  };

  React.useEffect(() => {
    setProgress(TOTAL / CANTIDAD_PREGUNTAS);
  }, [respuestas]);

  return (
    <div className="ecua-progress">
      {preguntas?.map((prg, index) => {
        const { _id: id } = prg;
        const color = handleColor(id, index);
        const variantRadius = handleRadius(index);
        const isLastQuestion = index === preguntas.length - 1;
        return (
          <div
            aria-valuemax={100}
            aria-valuemin={0}
            aria-valuenow={progress}
            className="ecua-progress-bar"
            key={index}
            role="progressbar"
            style={{
              width: `${progress}%`,
              backgroundColor: color,
              borderBottomRightRadius: isLastQuestion ? RADIUS : variantRadius,
              borderTopRightRadius: isLastQuestion ? RADIUS : variantRadius,
              borderBottomLeftRadius: !index ? RADIUS : "",
              borderTopLeftRadius: !index ? RADIUS : "",
              cursor: "pointer"
            }}
            onClick={() => handleClick(id)}
          >
            <div className="top text-center">
              <p>Pregunta {index + 1}</p>
              <i></i>
            </div>
          </div>
        );
      })}
      <h4 className="ecua-progress-text text-center">
        {complete !== undefined
          ? `Pregunta ${totalQuestions} de ${totalQuestions}`
          : `Pregunta ${indexPregunta + 1} de ${totalQuestions}`}
      </h4>
    </div>
  );

  function handleColor(id: string, index: number) {
    if (indexPregunta < index) return PROGRESS_STATES.NOT_SHOWED;
    const existsAnswer = Object.keys(respuestas).includes(id);
    if (existsAnswer) {
      if (indexPregunta === index && !respuestas[id]) {
        return PROGRESS_STATES.PENDING;
      }
      if (respuestas[id]) {
        return PROGRESS_STATES.ALREADY_ANSWERED;
      }
    }
    if (indexPregunta === index) return PROGRESS_STATES.IN_PROGRESS;
    return PROGRESS_STATES.PENDING;
  }

  function handleRadius(index: number) {
    if (indexPregunta === index) return RADIUS;
    return "";
  }
};
