import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";

export const fetchLocalidad = createAsyncThunk(
  "localidad/getLocalidad",
  async () => {
    const response = await mintelapi.get("/localidad");
    return response.data;
  }
);
