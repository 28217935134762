import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchInstituciones } from "./_fetchers";
import { INITIAL_STATE, institucion } from "./_namespace";

export const institucionesSlice = createSlice({
  name: "instituciones",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchInstituciones.fulfilled,
      (state, action: PayloadAction<institucion[]>) => {
        state.fetching = false;
        state.instituciones = action.payload;
      }
    );
  }
});
