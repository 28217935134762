import React from "react";
import InvestigacionImage from "../../assets/investigacion.png";
import Button from "../../components/Button";
import { handleStepsAction } from "./utils";

const Presentacion = ({ stateDispatch, nameInstitucion }: Props) => {
  const handleStart = () => {
    stateDispatch({ type: handleStepsAction.secondStep });
  };

  return (
    <div
      className="presentacion-content"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 12,
        marginBottom: 30
      }}
    >
      <div>
        <img
          alt="Encuesta logo"
          src={InvestigacionImage}
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width="200px"
        />
      </div>
      <div>
        <p>
          {nameInstitucion} quiere conocer su opinión sobre los siguientes
          temas. <br />
          No hay respuestas incorrectas solo responda bajo su mejor criterio
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            color="blue"
            radius={20}
            textColor="white"
            onClick={handleStart}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

interface Props {
  stateDispatch: (action: any) => void;
  nameInstitucion: string;
}

export default Presentacion;
