import React from "react";
import { Pregunta } from "../../../redux/slices/questions";
import InputTextOption from "../InputTextOption";
import OptionList from "../OptionList";
import { MultipleOptions } from "../MultipleOptions";

enum TipoRespuesta {
  Texto = "texto",
  Opciones = "opciones",
  OpcionesMultiples = "opciones_multiple"
}

const ContainerOpciones = ({ tipoRespuesta, pregunta }: Props) => {
  if (!tipoRespuesta || !pregunta) return <></>;

  switch (tipoRespuesta) {
    case TipoRespuesta.Texto:
      return (
        <InputTextOption
          key={pregunta._id}
          pregunta={pregunta}
        />
      );
    case TipoRespuesta.Opciones:
      return (
        <OptionList
          key={pregunta._id}
          pregunta={pregunta}
        />
      );
    case TipoRespuesta.OpcionesMultiples:
      return (
        <MultipleOptions
          key={pregunta._id}
          pregunta={pregunta}
        />
      );
    default:
      return <></>;
  }
};

interface Props {
  tipoRespuesta?: string;
  pregunta?: Pregunta;
}

export default ContainerOpciones;
