import { combineReducers } from "redux";
import { generoSlice } from "./genero/_reducers";
import { modalSlice } from "./modal";
import { provinciaSlice } from "./provincias";
import { questionSlice } from "./questions";
import { formacionSlice } from "./formacion";
import { nacionalidadSlice } from "./nacionalidad";
import { etniaSlice } from "./etnia";
import { evaluacionSlice } from "./evaluacion/_reducers";
import { personaSlice } from "./persona";
import { configurationSlice } from "./config";
import { resultadoSlice } from "./resultados/_reducers";
import { localidadSlice } from "./localidad";
import { estadisticasSlice } from "./estadisticas";
import { institucionesSlice } from "./instituciones";
import { encuestaSlice } from "./encuesta";

export default combineReducers({
  modal: modalSlice.reducer,
  question: questionSlice.reducer,
  provincia: provinciaSlice.reducer,
  genero: generoSlice.reducer,
  formacion: formacionSlice.reducer,
  nacionalidad: nacionalidadSlice.reducer,
  localidad: localidadSlice.reducer,
  etnia: etniaSlice.reducer,
  evaluacion: evaluacionSlice.reducer,
  persona: personaSlice.reducer,
  configuration: configurationSlice.reducer,
  resultado: resultadoSlice.reducer,
  estadisticas: estadisticasSlice.reducer,
  instituciones: institucionesSlice.reducer,
  encuesta: encuestaSlice.reducer
});
