import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFormacion } from "./_fetchers";
import { INITIAL_STATE, Formacion } from "./_namespace";

export const formacionSlice = createSlice({
  name: "formacion",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchFormacion.fulfilled,
      (state, action: PayloadAction<Formacion[]>) => {
        state.fetching = false;
        state.formaciones = action.payload;
      }
    );
  }
});
