import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import "./index.scss";
import EcuadorWhite from "../../assets/logos/EcuadorWhite.png";
import MintelWhite from "../../assets/logos/MintelWhite.png";
import Espol from "../../assets/logos/Espol.png";
import { NetworkTest } from "./NetworkConnectivity";

type Props = {
  children?: React.ReactNode;
};

const PageWrapper: React.FC<Props> = (props) => {
  const { children } = props;
  return (
    <div className="main-container">
      <Navbar />
      <NetworkTest>
        <div className="children-container">{children}</div>
      </NetworkTest>
      <Footer>
        <FooterText />
      </Footer>
    </div>
  );
};

const FooterText = () => {
  return (
    <div className="container-sm my-5">
      <div className="wrapper-responsive">
        <img
          alt="MintelWhite"
          height="150px"
          src={MintelWhite}
          width="auto"
        ></img>
        <img
          alt="Espol"
          className="px-5"
          height="75px"
          src={Espol}
          width="auto"
        ></img>
        <img
          alt="EcuadorWhite"
          height="150px"
          src={EcuadorWhite}
          width="auto"
        ></img>
      </div>
    </div>
  );
};

export default React.memo(PageWrapper);
