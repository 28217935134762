import React from "react";
import {
  questionSelector,
  NSQuestion,
  setOption,
  Pregunta
} from "../../../redux/slices/questions";
import { useAppDispatch, useAppSelector } from "../../../redux/store";

interface Props {
  pregunta: Pregunta;
}

const InputTextOption = ({ pregunta }: Props) => {
  const { respuestas } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const dispatch = useAppDispatch();
  const [respuesta, setRespuesta] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRespuesta(event.target.value);
    dispatch(setOption({ [pregunta._id]: event.target.value }));
  };

  React.useEffect(() => {
    const current = respuestas[pregunta?._id];
    if (typeof current === "string") setRespuesta(current);
  }, [respuestas, pregunta]);

  return (
    <div className="my-5">
      <input
        className="form-control"
        placeholder="Colocar Respuesta..."
        value={respuesta}
        onChange={handleChange}
      ></input>
    </div>
  );
};

export default InputTextOption;
