import React from "react";
import { useDispatch } from "react-redux";
import { setNetworkConnectivity } from "../../redux/slices/questions";
import { ReactElement } from "react";

const IMAGE_SIZE = 4516;
const LIMIT_SPEED = 0.2;
const MULTIMEDIA_IMAGE =
  "https://competenciasdigitales.cti.espol.edu.ec/back/multimedia/prod_logo_espol.png";

export const NetworkTest = ({ children }: { children: ReactElement }) => {
  const dispatch = useDispatch();
  const startTime = React.useRef(new Date().getTime());

  const handleNetworkConnection = async () => {
    const endTime = new Date().getTime();
    const timeDuration = (endTime - startTime.current) / 1000;
    const loadedBits = IMAGE_SIZE * 8;
    const speedInBps = loadedBits / timeDuration;
    const speedInKbps = speedInBps / 1024;
    const speedInMbps = speedInKbps / 1024;
    if (speedInMbps < LIMIT_SPEED) {
      dispatch(setNetworkConnectivity(0));
    } else {
      dispatch(setNetworkConnectivity(1));
    }
  };

  return (
    <>
      <img
        alt="Logo Espol"
        src={MULTIMEDIA_IMAGE}
        style={{ display: "none" }}
        onLoad={handleNetworkConnection}
      />
      {children}
    </>
  );
};
