import lupa from "../../assets/introCards/lupa.png";
import personas from "../../assets/introCards/personas.png";
import ordenador from "../../assets/introCards/ordenador.png";
import comModal from "../../assets/introCards/communicationModal.png";
import contentModal from "../../assets/introCards/contentModal.png";
import informationModal from "../../assets/introCards/informationModal.png";
const cards = [
  {
    area: "Información",
    title: "Gestion de la Información",
    description:
      "Se pondrán a prueba tus conocimientos, habilidades y actitudes para detectar necesidades de información, localizarla, evaluarla, almacenarla y gestionarla de manera eficaz y segura, para garantizar el aprendizaje permanente a lo largo de la vida",
    src: lupa,
    fullDescription:
      "Conocimientos, habilidades y actitudes para detectar necesidades de información, localizarla, evaluarla, almacenarla y gestionarla de manera eficaz y segura, para garantizar el aprendizaje permanente a lo largo de la vida.",
    competences: [
      "Identificar necesidades de información en entornos digitales.",
      "Buscar y localizar información en entornos digitales.",
      "Evaluar y filtrar la información obtenida en entornos digitales.",
      "Gestionar la información en entornos digitales.",
      "Resolver problemas en el manejo de información.",
      "Garantizar la seguridad en el manejo de la información.",
      "Identificar brechas digitales y buscar oportunidades de información para desarrollar las competencias digitales."
    ],
    modalsrc: informationModal
  },
  {
    area: "Comunicación y Colaboración",
    title: "Comunicación y Colaboración",
    description:
      "Se pondrán a prueba tus conocimientos, habilidades y actitudes para la comunicación y colaboración a través de medios digitales, gestión de la identidad digital y la privacidad, participación en la sociedad y la contribución al bienestar e inclusión social, mediante recursos y servicios digitales de manera eficaz y segura",
    src: personas,
    fullDescription:
      "Conocimientos, habilidades y actitudes para la comunicación y colaboración a través de medios digitales, gestión de la identidad digital y la privacidad, participación en la sociedad y la contribución al bienestar e inclusión social, mediante recursos y servicios digitales de manera eficaz y segura.",
    competences: [
      "Identificar necesidades de comunicación y colaboración en entornos digitales.",
      "Interactuar y compartir en entornos digitales, siguiendo las reglas de etiqueta (comportamiento en la red).",
      "Colaborar en entornos digitales.",
      "Gestionar la identidad digital y la privacidad en entornos digitales.",
      "Participar en sociedad y contribuir al bienestar social, la salud y el cuidado del medio ambiente en el uso de recursos y servicios digitales.",
      "Resolver problemas en la comunicación y la colaboración.",
      "Garantizar la seguridad en la comunicación y colaboración, incluyendo la seguridad de mis equipos y dispositivos."
    ],
    modalsrc: comModal
  },
  {
    area: "Creación de contenidos digitales",
    title: "Creación de Contenidos Digitales",
    description:
      "Se pondrán a prueba tus conocimientos, habilidades y actitudes para la creación y edición de contenidos digitales,  el uso de licencias de propiedad intelectual y el respeto a los derechos de autor, el manejo   de herramientas digitales para la innovación de una manera eficaz y segura",
    src: ordenador,
    fullDescription:
      "Conocimientos, habilidades y actitudes para la creación y edición de contenidos digitales, el uso de licencias de propiedad intelectual y el respeto a los derechos de autor, el manejo de herramientas digitales para la innovación de una manera eficaz y segura.",
    competences: [
      "Identificar necesidades de creación de contenidos en entornos digitales.",
      "Crear contenidos digitales.",
      "Editar contenidos digitales.",
      "Conocer y usar licencias de propiedad intelectual y derechos de autor.",
      "Manejar herramientas digitales para la innovación y el conocimiento.",
      "Resolver problemas en la creación de contenidos digitales.",
      "Garantizar la seguridad en la creación de contenidos digitales."
    ],
    modalsrc: contentModal
  }
];
export default cards;
