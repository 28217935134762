export const formatRequired = (fieldName: string, isMale = true): string => {
  return `${fieldName} es ${isMale ? "requerido" : "requerida"}.`;
};

export const formatValue = (fieldName: string): string => {
  return `${fieldName} debe ser un valor numérico.`;
};

export const formatOnlyText = (fieldName: string): string => {
  return `Ingrese un ${fieldName} válido.`;
};
