import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import "./index.scss";
type Props = {
  routes: route[];
};

const Hamburger: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState<boolean>(false);
  const [finishAnimation, setFinish] = React.useState<boolean>(false);
  const { routes } = props;
  const setRoutes = (routes: route[]) => {
    const links = routes.map((route, index) => {
      return (
        <li key={index}>
          <Link to={route.to}>{route.value}</Link>
        </li>
      );
    });
    return links;
  };
  return (
    <div>
      {show && (
        <div
          className={
            finishAnimation
              ? "hamburger-menu shadow-lg hamburger-menu-hide"
              : "hamburger-menu shadow-lg hamburger-menu-show"
          }
          onAnimationEnd={() => {
            if (finishAnimation) setShow(false);
          }}
        >
          <div
            className="hamburger-close"
            onClick={() => {
              setFinish(true);
            }}
          >
            Cerrar Menú
            <AiOutlineClose />
          </div>
          <ul className="hamburger-list">{setRoutes(routes)}</ul>
        </div>
      )}
      <div
        className="d-flex justify-content-center align-items-center ecua-menu"
        onClick={() => {
          setShow(true);
          setFinish(false);
        }}
      >
        <span>Menú&nbsp;</span>
        <GiHamburgerMenu></GiHamburgerMenu>
      </div>
    </div>
  );
};

export default Hamburger;
