import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "./_namespace";

export const modalSlice = createSlice({
  name: "modal",
  initialState: INITIAL_STATE,
  reducers: {
    open(state) {
      state.open = true;
    },
    close(state) {
      state.open = false;
      state.content = INITIAL_STATE.content;
    },
    setContent(state, action: PayloadAction<typeof INITIAL_STATE.content>) {
      state.content = action.payload;
    },
    restart(state) {
      state.open = INITIAL_STATE.open;
      state.content = INITIAL_STATE.content;
    }
  }
});
