import React from "react";

const DisplayImageModal = ({ image }: Props) => {
  return (
    <div
      className="container mb-5"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <img
        alt={`Zoom ${image}`}
        src={image}
        width="100%"
      />
    </div>
  );
};

interface Props {
  image: string;
}

export default DisplayImageModal;
