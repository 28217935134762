import { useNavigate } from "react-router-dom";
import { NSQuestion, Pregunta } from "../../../redux/slices/questions";
import { getNextID } from "../../../utils/preguntas";
import { useDispatch } from "react-redux";
import {
  setFinishPregunta,
  setNextCount,
  setViewPregunta
} from "../../../redux/slices/estadisticas/_actions";
import Button from "../../../components/Button";

interface NextButtonInterface {
  respuestas: NSQuestion.Answers;
  display: boolean;
  preguntas: Pregunta[];
  preguntaId: string;
}

export const NextButton = ({
  respuestas,
  preguntas,
  display,
  preguntaId
}: NextButtonInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    const id = getNextID(preguntas, preguntaId);
    navigate(`/evaluacion/${id}`);
    dispatch(setFinishPregunta(preguntaId));
    dispatch(setNextCount(preguntaId));
    dispatch(setViewPregunta(id));
  };
  const handleDisabled = () => {
    return !respuestas[preguntaId];
  };

  return display ? (
    <Button
      color={"blue"}
      disabled={handleDisabled()}
      id="next-button"
      radius={15}
      textColor={"white"}
      onClick={handleNext}
    >
      Siguiente
    </Button>
  ) : (
    <></>
  );
};
