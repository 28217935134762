import { close, open, setContent } from "../../redux/slices/modal";
import { useAppDispatch } from "../../redux/store";
import Button from "../Button";
import FormModal from "../FormModal";
import "./index.scss";

interface WarningModalInterface {
  questionsWithoutAnswers: number;
}

export const WarningModal = ({
  questionsWithoutAnswers
}: WarningModalInterface) => {
  const dispatch = useAppDispatch();

  const handleReturn = () => {
    dispatch(close());
  };

  const handleFinish = () => {
    dispatch(close());
    dispatch(setContent(<FormModal />));
    dispatch(open());
  };

  return (
    <div className="container mb-5">
      <h3 className="text-center">
        Hay {questionsWithoutAnswers} de 21 preguntas sin responder
      </h3>
      <div className="d-flex justify-content-center ecua-button-container">
        <Button
          className="m-1 ecua-navigation"
          color="gray"
          radius={15}
          textColor="black"
          onClick={handleReturn}
        >
          Regresar a la evaluación
        </Button>
        <Button
          className="m-1 ecua-navigation"
          color="blue"
          radius={15}
          textColor="white"
          onClick={handleFinish}
        >
          Continuar de todos modos
        </Button>
      </div>
    </div>
  );
};
