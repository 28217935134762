import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import DropItem from "../../components/DropItem";
import VerticalChart from "../../components/VerticalChart";
import { personaSelector, NSPersona } from "../../redux/slices/persona";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import { useAppSelector } from "../../redux/store";
import insignias from "../../assets/result/insignias.png";
import "./index.scss";
import Carousel from "../../components/Carousel";
import {
  CompetenciaEvaluada,
  Resultado
} from "../../redux/slices/resultados/_namespace";
import { SwiperSlide } from "swiper/react";
import { Curso } from "../../components/Curso";
import DescripcionNivel from "../../components/ResultContainer/DescripcionNivel";
import { TinyCurso } from "../../components/Curso/TinyCurso";

export const ResultContent = ({
  carrouselRef,
  courseListRef,
  resultado
}: any) => {
  const navigate = useNavigate();
  const { persona } = useAppSelector(personaSelector) as NSPersona.STATE;
  const { area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const [nota, setNota] = React.useState<number>(0);
  const [nivel, setNivel] = React.useState<number>(-1);
  const [promedio, setPromedio] = React.useState<number>(0);

  React.useEffect(() => {
    if (!persona) return;
    if (!resultado) return;
    setNota(resultado.puntaje);
    setNivel(resultado.nivel);
    setPromedio(resultado.promedio);
  }, [area, persona, resultado]);

  if (!resultado) return <></>;
  return (
    <>
      <div className="ecua-container p-5 mb-5">
        <DescripcionNivel
          nivel={nivel}
          persona={persona}
        />
      </div>
      <div className="row">
        <div className="break-page" />
        <div className="col-lg-6 mh-100 mb-5">
          <div className="ecua-container p-5 h-100">
            <h3 className="text-center">Comparativa a nivel nacional</h3>
            <VerticalChart
              nota={nota}
              promedioEcuatoriano={promedio}
            />
          </div>
        </div>
        <div className="break-page" />
        <div className="col-lg-6 mh-100 mb-5">
          <div className="ecua-container p-5 h-100">
            <h3 className="text-center">Resultados por competencia</h3>
            <ul className="list-group">
              {buildCompetencias(
                resultado?.competencias || [],
                nivel,
                resultado?.codigo || 0
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="break-page" />
      <div className="p-5 ecua-container mb-5">
        <h3>¡Cursos recomendados en base a tus competencias digitales!</h3>

        <div
          className="ecua-courses-list"
          ref={courseListRef}
        >
          <ul>{buildListCursosRecomendados(resultado)}</ul>
        </div>

        <div
          className="ecua-navigation"
          ref={carrouselRef}
        >
          <Carousel>{buildCursosRecomendados(resultado)}</Carousel>
        </div>
      </div>

      <div className="p-5 ecua-container mb-5 ecua-navigation">
        <div className="justify-content-center align-items-center">
          <h3>¡Obtén tu certificado y reclama tus insignias!</h3>
          <p>
            ¿Quieres tener más información de certificados, insignias y cursos
            que te pueden servir para mejorar tus competencias digitales?
          </p>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <img
              alt="insignias"
              className="img-fluid"
              src={insignias}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center mt-5 d-flex ">
            <Button
              className="m-2"
              color="green"
              radius={15}
              textColor="white"
            >
              Regístrate
            </Button>
            <Button
              className="m-2"
              color="blue"
              radius={15}
              textColor="white"
              onClick={() => navigate("/")}
            >
              Volver al Inicio
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  function buildCursosRecomendados(resultado?: Resultado) {
    const { competencias = [], codigo = 0 } = resultado || {};
    const competenciasCopy = [...competencias];
    const sortCompetencias = competenciasCopy?.sort(
      (a, b) => a?.codigo - b?.codigo
    );
    return sortCompetencias.map((comp) => {
      const { codigo: codigoCompetencia, competencia, cursos, nivel } = comp;
      const level = nivel || 1;
      return cursos?.map((e, index) => {
        const label = `Competencia ${codigo}.${codigoCompetencia}: ${competencia}`;
        if (e.nivel === level) {
          return (
            <SwiperSlide key={index}>
              <Curso
                imagen={e.imagen}
                label={label}
                link={e.link}
              />
            </SwiperSlide>
          );
        }
        return null;
      });
    });
  }

  function buildListCursosRecomendados(resultado?: Resultado) {
    const { competencias = [], codigo = 0 } = resultado || {};
    const ss = [...competencias].sort((a, b) => a.codigo - b.codigo);
    let some = false;
    const cursos = ss.map((comp) => {
      const { codigo: codigoCompetencia, competencia, cursos, nivel } = comp;
      return cursos?.map((e, index) => {
        if (e.nivel === nivel) {
          some = true;
          return (
            <TinyCurso
              codigo={codigo}
              codigoCompetencia={codigoCompetencia}
              key={index}
              label={competencia}
              link={e.link}
            />
          );
        }
        return null;
      });
    });
    const cursoss = [
      {
        link: "https://bit.ly/3IIp4qH",
        codigo: 2,
        competencia: "Editar contenidos digitales"
      },
      {
        link: "https://bit.ly/3IM6ygW",
        codigo: 1,
        competencia:
          "Garantizar la seguridad en la creación de contenidos digitales"
      }
    ];
    return some
      ? cursos
      : cursoss.map((curso, index) => (
          <TinyCurso
            codigo={codigo}
            codigoCompetencia={curso.codigo}
            key={index}
            label={curso.competencia}
            link={curso.link}
          />
        ));
  }

  function buildCompetencias(
    respuestas: CompetenciaEvaluada[],
    nivelArea: number,
    codigoArea: number
  ) {
    const result = [...respuestas].sort((a, b) => a.codigo - b.codigo);
    return result.map((competencia, index) => {
      return (
        <DropItem
          codigoArea={codigoArea}
          competencia={competencia}
          key={index}
          nivelArea={nivelArea}
        />
      );
    });
  }
};
