/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import { close, open, setContent } from "../../redux/slices/modal";
import { etniaSelector, NSEtnia, fetchEtnia } from "../../redux/slices/etnia";
import {
  fetchFormacion,
  formacionSelector,
  NSFormacion
} from "../../redux/slices/formacion";
import {
  fetchGeneros,
  generoSelector,
  NSGenero
} from "../../redux/slices/genero";
import {
  fetchNacionalidad,
  nacionalidadSelector,
  NSNacionalidad
} from "../../redux/slices/nacionalidad";
import {
  fetchInstituciones,
  institucionesSelector,
  NSInstituciones
} from "../../redux/slices/instituciones";
import {
  fetchProvincias,
  Provincia,
  provinciaSelector,
  NSProvincia
} from "../../redux/slices/provincias";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import {
  localidadSelector,
  NSLocalidad,
  fetchLocalidad
} from "../../redux/slices/localidad";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import Button from "../Button";
import "./index.scss";
import { submitResultados } from "./submit";
import InputText from "../Form/InputText";
import InputNumber from "../Form/InputNumber";
import Select from "../Form/Select";
import ConfirmCedula from "./ConfirmCedula";
import { personalFormValidationSchema } from "../../schemas/personalForm";
import Lottie from "../Lottie";
import {
  NSEstadisticas,
  estadisticasSelector
} from "../../redux/slices/estadisticas";
import { getIdPreguntasSinRespuesta } from "../../utils/preguntas";
import { TipoUsuario } from "./enums";
import {
  NSEncuestaMode,
  NSEncuestaSTATE,
  encuestaSelector
} from "../../redux/slices/encuesta";
import EncuestaComponent from "../../modules/Encuesta";

const FormModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { encuesta } = useAppSelector(encuestaSelector) as NSEncuestaSTATE;
  const { all } = useAppSelector(provinciaSelector) as NSProvincia.STATE;
  const { generos } = useAppSelector(generoSelector) as NSGenero.STATE;
  const { formaciones } = useAppSelector(
    formacionSelector
  ) as NSFormacion.STATE;
  const { etnias } = useAppSelector(etniaSelector) as NSEtnia.STATE;
  const { nacionalidades } = useAppSelector(
    nacionalidadSelector
  ) as NSNacionalidad.STATE;
  const { instituciones } = useAppSelector(
    institucionesSelector
  ) as NSInstituciones.STATE;
  const { localidades } = useAppSelector(
    localidadSelector
  ) as NSLocalidad.STATE;
  const { preguntas, respuestas, area, test, start_date } = useAppSelector(
    questionSelector
  ) as NSQuestion.STATE;
  const estadisticas = useAppSelector(
    estadisticasSelector
  ) as NSEstadisticas.STATE;
  const [isLoading, setIsLoading] = React.useState(false);

  const initialValues = {
    showCedula: "1",
    cedula: "",
    nombre: "",
    apellido: "",
    edad: "",
    genero: "",
    formacion: "",
    nacionalidad: "",
    provincia: "",
    canton: "",
    parroquia: "",
    localidad: "",
    etnia: "",
    correo: "",
    tipo: "",
    institucion: ""
  };

  const onSubmit = async (value: any) => {
    if (!isLoading) {
      setIsLoading(true);
      const idsPreguntasSkip = getIdPreguntasSinRespuesta(
        preguntas,
        respuestas
      );
      const totalRespondidas = preguntas.length - idsPreguntasSkip.length;
      const idResultado = await submitResultados({
        respuestas,
        ...value,
        preguntas,
        start_date,
        end_date: new Date(Date.now()),
        area,
        test,
        estadisticas: {
          ...estadisticas,
          preguntas_con_respuesta: totalRespondidas,
          preguntas_sin_respuesta: idsPreguntasSkip.length,
          ids_preguntas_sin_respuesta: idsPreguntasSkip
        }
      });
      dispatch(close());
      if (encuesta.mode === NSEncuestaMode.After) {
        dispatch(setContent(<EncuestaComponent isFinal />));
        dispatch(open());
      } else {
        navigate(`/resultados/${idResultado}`);
      }
    }
  };

  const statesEmpty = () => {
    const isProvincias = all.length;
    const isGeneros = generos.length;
    const isFormaciones = formaciones.length;
    const isEtnias = etnias.length;
    const isNacionalidades = nacionalidades.length;
    const isLocalidades = localidades.length;
    const isInstituciones = instituciones.length;

    return (
      isProvincias &&
      isFormaciones &&
      isEtnias &&
      isNacionalidades &&
      isGeneros &&
      isLocalidades &&
      isInstituciones
    );
  };

  React.useEffect(() => {
    if (!statesEmpty()) {
      dispatch(fetchProvincias());
      dispatch(fetchGeneros());
      dispatch(fetchFormacion());
      dispatch(fetchNacionalidad());
      dispatch(fetchEtnia());
      dispatch(fetchLocalidad());
      dispatch(fetchInstituciones());
    }
  }, [
    all.length,
    dispatch,
    etnias.length,
    formaciones.length,
    generos.length,
    nacionalidades.length,
    localidades.length
  ]);

  const showNextStep = (values: any) => {
    const { tipo, institucion } = values;
    if (tipo === "") return false;
    if (tipo === TipoUsuario.Ciudadano) return true;
    if (tipo !== TipoUsuario.Ciudadano && institucion === "") return false;
    return true;
  };

  const showOrganizacion = (tipo: string) => {
    return tipo && tipo !== TipoUsuario.Ciudadano;
  };

  const tipoClassName = (tipo: string) =>
    !tipo || tipo === TipoUsuario.Ciudadano ? "col-md-12" : "col-md-6";

  return (
    <div className="container mb-5">
      <Formik
        initialValues={initialValues}
        validationSchema={personalFormValidationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <>
              <h3 className="titulo-form">
                Completa los datos solicitados para ver los resultados de tu
                evaluación
              </h3>
              <p>
                Protección de Datos: le informamos que los datos personales y
                dirección de correo electrónico proporcionados, serán tratados
                bajo estricta confidencialidad, conforme la normativa legal
                vigente en el Ecuador
              </p>
              <div className="row">
                <ConfirmCedula
                  display={Boolean(parseInt(formik.values.showCedula))}
                  name="showCedula"
                  onChange={formik.handleChange}
                >
                  <div className="col-md-12">
                    <InputText
                      label="Cédula o Documento de identificación"
                      name="cedula"
                      required={Boolean(parseInt(formik.values.showCedula))}
                      requiredLabel={Boolean(
                        parseInt(formik.values.showCedula)
                      )}
                      value={formik.values.cedula}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </div>
                </ConfirmCedula>

                <div className={`${tipoClassName(formik.values.tipo)} mb-3`}>
                  <Select
                    label="¿Usted es?" //Esto maybe podamos cambiarlo por => ¿Pertenece a una organización?
                    name="tipo"
                    required
                    requiredLabel
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  >
                    <option value="">
                      ¿Ciudadano, funcionario público...?
                    </option>
                    <option
                      key={"ciudadano"}
                      value={"ciudadano"}
                    >
                      Ciudadano
                    </option>
                    <option
                      key={"funcionario"}
                      value={"funcionario"}
                    >
                      Funcionario Público
                    </option>
                  </Select>
                </div>

                {showOrganizacion(formik.values.tipo) && (
                  <div className="col-md-6 mb-3">
                    <Select
                      label="Institución"
                      name="institucion"
                      required
                      requiredLabel
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    >
                      <option value="">
                        Seleccione la institución a la que pertenece...
                      </option>
                      {instituciones.map((institucion) => (
                        <option
                          key={institucion._id}
                          value={institucion._id}
                        >
                          {institucion.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
                {showNextStep(formik.values) && (
                  <>
                    <div className="col-md-6 mb-3">
                      <InputText
                        label="Nombre"
                        name="nombre"
                        required={true}
                        requiredLabel={true}
                        value={formik.values.nombre}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <InputText
                        label="Apellido"
                        name="apellido"
                        value={formik.values.apellido}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <InputNumber
                        label="Edad"
                        name="edad"
                        value={formik.values.edad}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <Select
                        label="Género"
                        name="genero"
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value={""}>Selecciona un género...</option>
                        {generos.map((genero) => (
                          <option
                            key={genero._id}
                            value={genero.genero}
                          >
                            {genero.genero}
                          </option>
                        ))}
                      </Select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Select
                        label="Formación"
                        name="formacion"
                        value={formik.values.formacion}
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value="">Selecciona una Formación...</option>
                        {formaciones.map((formacion) => {
                          return (
                            <option
                              key={formacion._id}
                              value={formacion.formacion}
                            >
                              {formacion.formacion}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Select
                        label="Etnia"
                        name="etnia"
                        value={formik.values.etnia}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value={""}>Selecciona una etnia...</option>
                        {etnias.map((etnia) => (
                          <option
                            key={etnia._id}
                            value={etnia.etnia}
                          >
                            {etnia.etnia}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Select
                        id="nacionalidad"
                        label="Nacionalidad"
                        name="nacionalidad"
                        value={formik.values.nacionalidad}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value={""}>
                          Selecciona una nacionalidad...
                        </option>
                        {nacionalidades.map((nacionalidad) => (
                          <option
                            key={nacionalidad._id}
                            value={nacionalidad.nacionalidad}
                          >
                            {nacionalidad.nacionalidad}
                          </option>
                        ))}
                      </Select>
                    </div>

                    <div className="col-md-6 mb-3">
                      <Select
                        label="Provincia"
                        name="provincia"
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            canton: "",
                            parroquia: ""
                          });
                          formik.handleChange(e);
                        }}
                      >
                        <option value="">Selecciona una Provincia...</option>
                        {buildProvincias(all)}
                      </Select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Select
                        disabled={formik.values.provincia === ""}
                        label="Cantón"
                        name="canton"
                        value={formik.values.canton}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value="">Selecciona un Cantón...</option>
                        {formik.values.provincia ? (
                          buildCantones(formik.values.provincia, all)
                        ) : (
                          <></>
                        )}
                      </Select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <Select
                        disabled={formik.values.canton === ""}
                        label="Parroquia"
                        name="parroquia"
                        value={formik.values.parroquia}
                        required
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value="">Selecciona una Parroquia...</option>
                        {formik.values.canton ? (
                          buildParroquia(
                            formik.values.canton,
                            formik.values.provincia,
                            all
                          )
                        ) : (
                          <></>
                        )}
                      </Select>
                    </div>
                    <div className="col-md-12 mb-3">
                      <Select
                        label="Punto Digital Gratuito"
                        name="localidad"
                        value={formik.values.localidad}
                        requiredLabel
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                      >
                        <option value="">
                          {
                            'Seleccione el "Punto Digital" desde dónde está realizando la evaluación...'
                          }
                        </option>
                        {localidades.map((localidad) => {
                          return (
                            <option
                              key={localidad._id}
                              value={localidad.punto_encuentro}
                            >
                              {localidad.punto_encuentro}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="col-md-12">
                      <div>
                        <p>
                          <strong>
                            Recibe propuestas de cursos basadas en tus
                            resultados registrando tu correo electrónico y lleva
                            tus competencias digitales al siguiente nivel
                          </strong>
                        </p>
                      </div>
                      <input
                        className="form-control"
                        name="correo"
                        placeholder="Ingrese su correo electrónico..."
                        type="email"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                      ></input>
                    </div>
                    {isLoading ? (
                      <Lottie />
                    ) : (
                      <div className="col-md-12 d-flex justify-content-center align-items-center pt-3">
                        <Button
                          color="blue"
                          disabled={isLoading}
                          radius={15}
                          textColor="white"
                          type="submit"
                          onClick={() => formik.handleSubmit()}
                        >
                          Visualizar Resultados
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );

  function filterProvincias(provincias: typeof all) {
    return provincias;
  }

  function filterCantones(id: string, provincias: typeof all) {
    const provincia = provincias.filter((value) => value._id === id)[0];
    return provincia.cantones;
  }

  function filterParroquias(
    id: string,
    cantones: ReturnType<typeof filterCantones>
  ) {
    const canton = cantones.filter((value) => value._id === id)[0];
    if (canton.parroquias.length === 0)
      return [{ _id: "none", parroquia: "No hay parroquias para esta zona" }];
    return canton.parroquias;
  }

  function buildProvincias(all: Provincia[]) {
    return filterProvincias(all).map((value) => {
      return (
        <option
          key={value._id}
          value={value._id}
        >
          {value.provincia}
        </option>
      );
    });
  }

  function buildCantones(provincia: string, all: Provincia[]) {
    return filterCantones(provincia, all).map((value) => (
      <option
        key={value._id}
        value={value._id}
      >
        {value.canton}
      </option>
    ));
  }

  function buildParroquia(canton: string, provincia: string, all: Provincia[]) {
    return filterParroquias(canton, filterCantones(provincia, all)).map(
      (value) => (
        <option
          key={value._id}
          value={value._id}
        >
          {value.parroquia}
        </option>
      )
    );
  }
};

export default FormModal;
