import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEncuesta } from "./_fetchers";
import { Encuesta, INITIAL_STATE } from "./_namespace";

export const encuestaSlice = createSlice({
  name: "encuesta",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchEncuesta.fulfilled,
      (state, action: PayloadAction<Encuesta>) => {
        state.fetching = false;
        state.encuesta = action.payload;
      }
    );
  }
});
