import React from "react";
import { useAppSelector } from "../../redux/store";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import {
  SkipButton,
  FinishButton,
  NextButton,
  BackButton
} from "./NavigationButtons";
import "./index.scss";
import { Enunciado } from "./Enunciado";
import { usePreguntas } from "../../hooks/usePreguntas";
import Lottie from "../../components/Lottie";
import Tour from "../../components/Tour";
import ContainerOpciones from "./ContainerOpciones";

interface Props {
  questionId: string;
}

const QuestionContainer = ({ questionId }: Props) => {
  const [finish, setFinish] = React.useState(false);
  const { fetching, respuestas } = useAppSelector(
    questionSelector
  ) as NSQuestion.STATE;

  const { pregunta, tipoRespuesta, isFirst, isLast, preguntasSorted } =
    usePreguntas(questionId);

  const canRenderTour = () => {
    const hasAnswer = Object.keys(respuestas).includes(pregunta?._id || "");
    return isFirst && !hasAnswer;
  };

  return (
    <div className="question-container container pt-5 px-0">
      {fetching ? (
        <Lottie />
      ) : (
        <div className="px-3 test-container">
          {canRenderTour() && <Tour />}
          <Enunciado
            canRenderTool={!canRenderTour()}
            disableModals={finish}
            key={pregunta?._id}
            pregunta={pregunta}
            totalPreguntas={preguntasSorted.length}
          />
          <div
            className="container-fluid"
            id="question-options"
          >
            <ContainerOpciones
              key={pregunta?._id}
              pregunta={pregunta}
              tipoRespuesta={tipoRespuesta}
            />
          </div>
        </div>
      )}
      <div className="question-container-footer">
        <BackButton
          disabled={isFirst}
          preguntaId={questionId}
          preguntas={preguntasSorted}
        />
        <div className="button-container">
          <SkipButton
            isLastQuestion={isLast}
            preguntaId={questionId}
            preguntas={preguntasSorted}
            respuestas={respuestas}
          />

          <FinishButton
            display={isLast}
            preguntaId={questionId}
            preguntas={preguntasSorted}
            respuestas={respuestas}
            onClick={() => setFinish(true)}
          />

          <NextButton
            display={!isLast}
            preguntaId={questionId}
            preguntas={preguntasSorted}
            respuestas={respuestas}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionContainer;
