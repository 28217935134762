export declare namespace NSFormacion {
  type STATE = { fetching: boolean; formaciones: Formacion[] };
}

export type Formacion = {
  formacion: string;
  _id: string;
};

export const INITIAL_STATE: NSFormacion.STATE = {
  fetching: false,
  formaciones: []
};
