import React from "react";
import image from "../../assets/introy.png";
import condor from "../../assets/introCards/condor.png";
import tortuga from "../../assets/introCards/tortuga.png";
import jaguar from "../../assets/introCards/jaguar.png";
import PageWrapper from "../../components/PageWrapper";
import "./index.scss";
const Competence = () => {
  return (
    <div>
      <PageWrapper>
        <CompetenceComponent />
      </PageWrapper>
    </div>
  );
};

const CompetenceComponent = () => {
  return (
    <div>
      <div className="container">
        <div className="competencia-container p-5 my-5">
          <h1 className="text-center">Competencias digitales</h1>
          <p className="texto">
            Las competencias digitales son aquellos conocimientos, habilidades y
            actitudes que permiten el uso de tecnologías digitales y la
            resolución de problemas para la búsqueda y gestión de la
            información, la comunicación y colaboración y la creación de
            contenidos digitales de manera segura, crítica y responsable para la
            participación social, el empleo, el consumo o el aprendizaje.
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <div className="imagenes">
              <img
                alt="imagen"
                className="img-fluid2"
                src={image}
              ></img>
            </div>
          </div>
        </div>
        <h1 className="text-center">Áreas de Competencia</h1>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            <div className="informacion-container p-5">
              <h3 className="text-center">Información</h3>
              <p className="texto">
                Conocimientos, habilidades y actitudes para detectar necesidades
                de información, localizarla, evaluarla, almacenarla y
                gestionarla de manera eficaz y segura, para garantizar el
                aprendizaje permanente a lo largo de la vida
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="comunicacion-container p-5">
              <h3 className="text-center">Comunicación y colaboración</h3>
              <p className="texto">
                Conocimientos, habilidades y actitudes para la comunicación y
                colaboración a través de medios digitales, gestión de la
                identidad digital y la privacidad, participación en la sociedad
                y la contribución al bienestar e inclusión social, mediante
                recursos y servicios digitales de manera eficaz y segura.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="contenido-container p-5">
              <h3 className="text-center">Creación de contenidos digitales</h3>
              <p className="texto">
                Conocimientos, habilidades y actitudes para la creación y
                edición de contenidos digitales, el uso de licencias de
                propiedad intelectual y el respeto a los derechos de autor, el
                manejo de herramientas digitales para la innovación de una
                manera eficaz y segura.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1>Cada área está formada por las siguientes competencias:</h1>
        <div className="informacion-container p-5 my-3">
          <h3 className="text-center">Información</h3>
          <ul className="texto-lista">
            <div className="imagenes">
              <img
                alt="imagen"
                className="condor"
                src={condor}
              ></img>
            </div>
            <li>
              Identificar necesidades de información en entornos digitales.
            </li>
            <li>Buscar y localizar información en entornos digitales.</li>
            <li>
              Evaluar y filtrar la información obtenida en entornos digitales.
            </li>
            <li>Gestionar la información en entornos digitales.</li>
            <li>Resolver problemas en el manejo de información.</li>
            <li>Garantizar la seguridad en el manejo de la información.</li>
            <li>
              Identificar brechas digitales y buscar oportunidades de
              información para desarrollar las competencias digitales.
            </li>
          </ul>
        </div>
        <div className="comunicacion-container p-5 my-3">
          <h3 className="text-center">Comunicación y colaboración</h3>
          <ul className="texto-lista">
            <div className="imagenes">
              <img
                alt="imagen"
                className="jaguar"
                src={jaguar}
              ></img>
            </div>
            <li>
              Identificar necesidades de comunicación y colaboración en entornos
              digitales.
            </li>
            <li>
              Interactuar y compartir en entornos digitales, siguiendo las
              reglas de netiqueta comportamiento en la red.
            </li>
            <li>Colaborar en entornos digitales.</li>
            <li>
              Gestionar la identidad digital y la privacidad en entornos
              digitales.
            </li>
            <li>
              Participar en sociedad y contribuir al bienestar social, la salud
              y el cuidado del medio ambiente en el uso de recursos y servicios
              digitales.
            </li>
            <li>Resolver problemas en la comunicación y la colaboración.</li>
            <li>
              Garantizar la seguridad en la comunicación y colaboración,
              incluyendo la seguridad de mis equipos y dispositivos.
            </li>
          </ul>
        </div>
        <div className="contenido-container p-5 my-3">
          <h3>Creación de contenidos digitales</h3>
          <ul className="texto">
            <div className="imagenes">
              <img
                alt="imagen"
                className="tortuga"
                src={tortuga}
              ></img>
            </div>
            <li>
              Identificar necesidades de creación de contenidos en entornos
              digitales.
            </li>
            <li>Crear contenidos digitales.</li>
            <li>Editar contenidos digitales.</li>
            <li>
              Conocer y usar licencias de propiedad intelectual y derechos de
              autor.
            </li>
            <li>
              Manejar herramientas digitales para la innovación y el
              conocimiento.
            </li>
            <li>Resolver problemas en la creación de contenidos digitales.</li>
            <li>
              Garantizar la seguridad en la creación de contenidos digitales.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Competence;
