export declare namespace NSEvaluacion {
  type STATE = {
    cedula: string;
    resuelto: string[];
  };
}

export const INITIAL_STATE: NSEvaluacion.STATE = {
  cedula: "",
  resuelto: []
};
