import React from "react";
import "./index.scss";
type Props = {
  display: boolean;
  name: string;
  onChange: (value: any) => void;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ConfirmCedula: React.FC<Props> = (props) => {
  const { display, name, className, children, onChange, ...rest } = props;
  const [show, setShow] = React.useState(display);

  React.useEffect(() => {
    setShow(display);
  }, [display]);

  return (
    <div className="col-md-12 mb-3">
      {/* <label className={`me-5 ${show ? "mb-3" : ""}`}>
        ¿Usted desea ingresar su cédula/documento de identificación?
      </label> */}
      {/* <div className="form-check form-check-inline">
        <label
          className="form-check-label"
          id="yes"
        >
          Sí
        </label>
        <input
          checked={show}
          className="form-check-input"
          id="yes"
          name={name}
          type="radio"
          value={1}
          onChange={onChange}
        />
      </div>
      <div className="form-check form-check-inline">
        <label
          className="form-check-label"
          id="no"
        >
          No
        </label>
        <input
          checked={!show}
          className="form-check-input"
          id="no"
          name={name}
          type="radio"
          value={0}
          onChange={onChange}
        />
      </div> */}

      <div
        className={`confirmCedula-${show ? "show" : "hide"}`}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

export default ConfirmCedula;
