import React from "react";
import QuestionLabel from "./components/atoms/QuestionLabel";
import Option from "./components/atoms/Option";
import { isMultipleOption } from "./utils/isMultiple";
import EncuestaFooter from "./components/molecules/EncuestaFooter";
import { useAppSelector } from "../../redux/store";
import {
  Encuesta,
  NSEncuestaOption,
  NSEncuestaSTATE,
  encuestaSelector
} from "../../redux/slices/encuesta";
import { addAnswer } from "./utils/handleResponse";

const Preguntas = ({ dispatch }: Props) => {
  const { encuesta } = useAppSelector(encuestaSelector) as NSEncuestaSTATE;
  const { preguntas = [], _id: idEncuesta } = (encuesta || {}) as Encuesta;
  const [visibleQuestion, setVisibleQuestion] = React.useState(0);
  const [respuestas, setRespuestas] = React.useState<Record<string, string[]>>(
    {}
  );
  const [isMultipleOptionQuestion, setIsMultipleOptionQuestion] =
    React.useState(false);

  React.useEffect(() => {
    const opciones = preguntas[visibleQuestion]?.options;
    opciones && setIsMultipleOptionQuestion(isMultipleOption(opciones));
  }, [visibleQuestion]);

  const handleResponse = (event: any) => {
    const pregunta = preguntas[visibleQuestion]?.questionText || "";
    const finalRespuestas = addAnswer(
      event.target.value,
      respuestas,
      pregunta,
      isMultipleOptionQuestion
    );
    setRespuestas({ ...respuestas, [pregunta]: finalRespuestas });
  };

  return (
    <div className="pregunta-container">
      {preguntas.map((pregunta, index) => {
        return (
          index === visibleQuestion && (
            <div key={index}>
              <QuestionLabel question={pregunta.questionText} />
              <form>
                {pregunta.options.map(
                  (option: NSEncuestaOption, index: number) => (
                    <Option
                      isMultiple={isMultipleOptionQuestion}
                      key={index}
                      name={pregunta.questionText}
                      option={option}
                      onChange={handleResponse}
                    />
                  )
                )}
              </form>
            </div>
          )
        );
      })}
      <EncuestaFooter
        dispatch={dispatch}
        idEncuesta={idEncuesta}
        indexQuestion={visibleQuestion}
        respuestas={respuestas}
        setIndexQuestion={setVisibleQuestion}
        totalPreguntas={preguntas.length}
      />
    </div>
  );
};

interface Props {
  dispatch: (value: any) => void;
}

export default Preguntas;
