import React, { useState, useEffect, useContext } from "react";

const OnlineStatusContext = React.createContext(true);

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export const OnlineStatusProvider: React.FC<Props> = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);

  useEffect(() => {
    window.addEventListener(
      "offline",
      () => {
        setOnlineStatus(false);
      },
      { passive: true }
    );
    window.addEventListener(
      "online",
      () => {
        setOnlineStatus(true);
      },
      { passive: true }
    );

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};
