import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchEtnia } from "./_fetchers";
import { INITIAL_STATE, Etnia } from "./_namespace";

export const etniaSlice = createSlice({
  name: "etnia",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchEtnia.fulfilled,
      (state, action: PayloadAction<Etnia[]>) => {
        state.fetching = false;
        state.etnias = action.payload;
      }
    );
  }
});
