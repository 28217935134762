import mintelapi from "../../../mintelapi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NSQuestion } from "./_namespace";

export const fetchQuestion = createAsyncThunk(
  "question/getQuestion",
  async () => {
    const response = await mintelapi.get("/preguntas/one");
    return response.data;
  }
);

export const fetchPrueba = createAsyncThunk(
  "question/fetchPrueba",
  async (params: NSQuestion.PruebaParams) => {
    const response = await mintelapi.get("/tests/getTest", {
      params: {
        area: params.area,
        networkConnectivity: params.networkConnectivity
      }
    });
    return response.data;
  }
);

export const fetchArea = createAsyncThunk(
  "area/fetchArea",
  async (params: NSQuestion.PruebaParams) => {
    const response = await mintelapi.get("/area", {
      params: {
        area: params.area
      }
    });
    return response.data;
  }
);
