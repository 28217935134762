import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../../utils/useWindowDimensions";
import "./index.scss";
import Hamburger from "./Hamburger";
import Espol from "../../assets/logos/Espol.png";
import MintelWhite from "../../assets/logos/MintelWhite.png";

const Navbar = () => {
  const { width } = useWindowDimensions();

  const routes: route[] = [
    { to: "/", value: "Inicio" },
    { to: "/competence", value: "Competencias Digitales" },
    { to: "/nosotros", value: "Sobre Nosotros" }
  ];

  const setRoutes = (routes: route[]) => {
    const links = routes.map((route, index) => {
      return (
        <li key={index}>
          <Link to={route.to}>{route.value}</Link>
        </li>
      );
    });
    return links;
  };

  return (
    <div className="ecua-navbar">
      <div>
        <img
          alt="MintelWhite"
          height={width > 500 ? "90px" : "75px"}
          src={MintelWhite}
          width="auto"
        ></img>
        {width > 500 ? (
          <img
            alt="EcuadorWhite"
            height="40px"
            src={Espol}
            width="auto"
          ></img>
        ) : (
          <div></div>
        )}
      </div>

      <div>
        {width > 1044 ? (
          <ul className="ecua-ul-navbar">{setRoutes(routes)} </ul>
        ) : (
          <Hamburger routes={routes}></Hamburger>
        )}
      </div>
    </div>
  );
};

export default Navbar;
