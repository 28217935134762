import "./index.scss";

interface Props {
  link: string;
  imagen: string;
  label: string;
}

export const Curso = ({ link, imagen, label }: Props) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      className="ecua-wrapper"
      style={{ cursor: "pointer" }}
      onClick={() => openInNewTab(link)}
    >
      <div className="ecua-label">
        <h4 className="ecua-text">{label}</h4>
      </div>
      <img
        alt={"Cursos relacionados"}
        src={process.env.REACT_APP_BACK_MULTIMEDIA + imagen}
      />
    </div>
  );
};
