import React from "react";
import "./index.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Footer: React.FC<Props> = (props) => {
  const { className, children, ...rest } = props;
  return (
    <div
      className={`ecua-footer ${className}`}
      {...rest}
    >
      {children}
      <div className="ecua-footer-flag">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="d-flex align-items-center justify-content-center text-white ecua-footer-text">
        <h4 className="text-center">
          Proyecto desarrollado en conjunto por el Ministerio de
          Telecomunicaciones y de la Sociedad de la Información y la Escuela
          Superior Politécnica del Litoral
        </h4>
      </div>
    </div>
  );
};

export default Footer;
