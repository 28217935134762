import { questionSlice } from "./_reducers";

const actions = questionSlice.actions;

export const {
  setCompetences,
  setNetworkConnectivity,
  cleanQuestion,
  setStartTest,
  setEndTest,
  setOption
} = actions;
