import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Offline from "./routes/Offline";
import { useOnlineStatus } from "./utils/online";
import routes from "./routes";
import Modal from "./components/Modal";
import { useAppDispatch } from "./redux/store";
import { restart } from "./redux/slices/modal";
import { fetchConfiguration } from "./redux/slices/config";

function App() {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(restart());
    dispatch(fetchConfiguration());
  }, [dispatch]);
  return (
    <Online>
      <RouteGenerator>
        <Modal />
      </RouteGenerator>
    </Online>
  );
}

type OnlineProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Online: React.FC<OnlineProps> = (props) => {
  const { children, ...rest } = props;
  const isOnline = useOnlineStatus();
  return <div {...rest}>{isOnline ? children : <Offline />}</div>;
};

type RouteProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const RouteGenerator: React.FC<RouteProps> = (props) => {
  const { children } = props;
  return (
    <BrowserRouter>
      {children}
      <Routes>
        {routes.map((route) => {
          const Element = route.element;
          return (
            <Route
              element={<Element {...route.props} />}
              key={route.path}
              path={route.path}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
