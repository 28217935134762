export enum handleStepsAction {
  firstStep = "goTofirstStep",
  secondStep = "goToSecondStep",
  thirdStep = "goToThirdStep"
}

export enum Step {
  Presentacion = "Presentacion",
  Preguntas = "Preguntas",
  Final = "Final"
}
export const handleSteps = (
  state: { component: Step },
  action: { type: handleStepsAction }
) => {
  switch (action.type) {
    case handleStepsAction.firstStep:
      return {
        component: Step.Presentacion
      };
    case handleStepsAction.secondStep:
      return {
        component: Step.Preguntas
      };
    case handleStepsAction.thirdStep:
      return {
        component: Step.Final
      };
  }
};
