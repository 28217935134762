import React from "react";

import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill
} from "react-icons/bs";
import "./index.scss";
import { CompetenciaEvaluada } from "../../redux/slices/resultados/_namespace";

type Props = {
  competencia: Partial<CompetenciaEvaluada>;
  nivelArea: number;
  codigoArea: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLLIElement>, HTMLLIElement>;

const DropItem: React.FC<Props> = (props) => {
  const { competencia, nivelArea, codigoArea, ...rest } = props;
  const {
    competencia: label,
    nivel: nivelCompetencia,
    puntaje,
    codigo
  } = competencia;
  const [show, setShow] = React.useState(false);

  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <li
      {...rest}
      className="list-group-item"
    >
      <div className="ecua-dropitem d-flex align-items-center">
        <div className={`ecua-puntaje-${handleStyle(nivelCompetencia || 0)}`}>
          {puntaje}/6
        </div>
        <div>
          <div>
            {`${codigoArea}.${codigo}: `}
            {label}
          </div>
          <div
            className="ecua-drop-animation"
            ref={contentRef}
            style={
              show
                ? {
                    height: contentRef.current
                      ? contentRef.current.scrollHeight + "px"
                      : "0px",
                    marginTop: "10px",
                    color: "gray"
                  }
                : { height: "0px" }
            }
          >
            {competencia.descripcion}
          </div>
        </div>
        <div className="ecua-arrow">
          {show ? (
            <BsFillArrowUpCircleFill
              style={{ color: "#cd7f32" }}
              onClick={handleClick}
            />
          ) : (
            <BsFillArrowDownCircleFill
              style={{ color: "#cd7f32" }}
              onClick={handleClick}
            />
          )}
        </div>
      </div>
    </li>
  );

  function handleClick() {
    setShow((prev) => !prev);
  }

  function handleStyle(nivelCompetencia: number) {
    if (nivelCompetencia <= 1) {
      return "bajo";
    }
    if (nivelCompetencia === 2) {
      return "intermedio";
    }
    if (nivelCompetencia === 3) {
      return "completo";
    }
    return "bajo";
  }
};

export default DropItem;
