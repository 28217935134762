import Button from "../../components/Button";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="ecua-404">
      <div className="ecua-404-error">404</div>
      <h3 className="text-center">¡Ups! Página no Encontrada.</h3>
      <NavigateHomeButton></NavigateHomeButton>
    </div>
  );
};

const NavigateHomeButton = () => {
  const navigate = useNavigate();
  const routeChange = () => {
    navigate("/");
  };
  return (
    <Button
      color="blue"
      textColor="white"
      onClick={routeChange}
    >
      Volver al Inicio
    </Button>
  );
};

export default Error404;
