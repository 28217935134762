import React from "react";
import "./index.scss";
import Button from "../Button";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { open, setContent } from "../../redux/slices/modal";
import CardModalContent from "./CardModalContent";
import cards from "./cardjson";
import {
  evaluacionSelector,
  NSEvaluacion
} from "../../redux/slices/evaluacion";
import {
  NSQuestion,
  cleanQuestion,
  fetchPrueba,
  questionSelector,
  setCompetences
} from "../../redux/slices/questions";
import { cleanEstadisticas } from "../../redux/slices/estadisticas/_actions";
import { fetchEncuesta } from "../../redux/slices/encuesta";
import Lottie from "../Lottie";

type Props = {
  completedTest?: boolean;
};

const CardList: React.FC<Props> = (props) => {
  const { completedTest } = props;
  const { resuelto } = useAppSelector(evaluacionSelector) as NSEvaluacion.STATE;
  const filteredCards = completedTest
    ? cards.filter((card) => !resuelto.includes(card.title))
    : cards;
  return (
    <div
      className="row my-5"
      id="competence-test"
    >
      {filteredCards.map((card) => {
        return (
          <div
            className="col-xl my-5"
            key={card.title}
          >
            <Card
              card={card}
              key={card.title}
            />
          </div>
        );
      })}
    </div>
  );
};

type CardProps = {
  card: typeof cards[number];
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Card: React.FC<CardProps> = (props) => {
  const dispatch = useAppDispatch();
  const { card, className, ...rest } = props;
  const { networkConnectivity } = useAppSelector(
    questionSelector
  ) as NSQuestion.STATE;

  return (
    <div
      className={`ecua-card ${className ? className : ""}`}
      {...rest}
    >
      <div className="image-content">
        <img
          alt={card.title}
          className="img-design"
          src={card.src}
        ></img>
      </div>
      <div className="ecua-title">{card.title}</div>
      <p className="ecua-description">{card.description}</p>
      {networkConnectivity !== undefined ? (
        <Button
          color="blue"
          radius={7}
          textColor="white"
          onClick={() => {
            dispatch(setContent(<CardModalContent card={card} />));
            dispatch(open());
            dispatch(cleanQuestion());
            dispatch(cleanEstadisticas());
            dispatch(fetchEncuesta());
            dispatch(fetchPrueba({ area: card.area, networkConnectivity }));
            dispatch(setCompetences(card.area));
          }}
        >
          Ver Más
        </Button>
      ) : (
        <Lottie />
      )}
    </div>
  );
};

export default CardList;
