export declare namespace NSProvincia {
  type STATE = {
    fetching: boolean;
    all: Provincia[];
    provincia: Provincia | undefined;
    canton: Canton | undefined;
    parroquia: Parroquia | undefined;
  };
}

export type Provincia = {
  _id: string;
  provincia: string;
  cantones: [Canton];
};

export type Canton = {
  _id: string;
  canton: string;
  parroquias: Parroquia[];
};

export type Parroquia = {
  _id: string;
  parroquia: string;
};

export const INITIAL_STATE = {
  fetching: false,
  all: [],
  provincia: undefined,
  canton: undefined,
  parroquia: undefined
} as NSProvincia.STATE;
