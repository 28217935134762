export declare namespace NSConfig {
  type STATE = {
    configuration: Configuration[];
    fetching: boolean;
  };
}

export type Configuration = {
  _id: string;
  label: string;
  value: string;
};

export const INITIAL_STATE: NSConfig.STATE = {
  configuration: [],
  fetching: false
};
