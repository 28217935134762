import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE, NSEvaluacion } from "./_namespace";

export const evaluacionSlice = createSlice({
  name: "evaluacion",
  initialState: INITIAL_STATE,
  reducers: {
    setData(state, action: PayloadAction<NSEvaluacion.STATE>) {
      state.cedula = action.payload.cedula;
      state.resuelto = action.payload.resuelto;
    },
    addEvaluacion(state, action: PayloadAction<string>) {
      const currentState = current(state);
      state.resuelto = [...currentState.resuelto, action.payload];
    },
    cleanEvaluacion(state) {
      state.cedula = INITIAL_STATE.cedula;
      state.resuelto = INITIAL_STATE.resuelto;
    }
  }
});
