import React from "react";
import EvaluacionContainer from "../../components/EvaluacionContainer";
import QuestionHeader from "../../modules/Pregunta/Header";

const TestEnd = () => {
  return (
    <div className="main-container">
      <QuestionHeader
        complete={true}
        header="Evaluación Finalizada"
      ></QuestionHeader>
      <EvaluacionContainer />
    </div>
  );
};

export default TestEnd;
