export declare namespace NSNacionalidad {
  type STATE = {
    fetching: boolean;
    nacionalidades: Nacionalidad[];
  };
}

export type Nacionalidad = {
  nacionalidad: string;
  _id: string;
};

export const INITIAL_STATE: NSNacionalidad.STATE = {
  fetching: false,
  nacionalidades: []
};
