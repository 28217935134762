import { NSQuestion, Pregunta } from "../redux/slices/questions";

export const getIndexPreguntaById = (
  preguntas: Pregunta[],
  id: string
): number => {
  return preguntas.findIndex((pregunta) => pregunta._id === id);
};

export const getPreguntaByID = (preguntas: Pregunta[], preguntaId: string) => {
  return preguntas.find((pregunta) => pregunta._id === preguntaId);
};

export const getNextID = (preguntas: Pregunta[], preguntaId: string) => {
  const index = preguntas.findIndex((pregunta) => pregunta._id === preguntaId);
  if (index === -1) return "";
  if (index + 1 >= preguntas.length) return "";
  return preguntas[index + 1]?._id;
};

export const getBackID = (preguntas: Pregunta[], preguntaId: string) => {
  const index = preguntas.findIndex((pregunta) => pregunta._id === preguntaId);
  if (index === -1) return "";
  if (index - 1 < 0) return "";
  return preguntas[index - 1]?._id;
};

export const isFirstQuestion = (preguntas: Pregunta[], preguntaId: string) => {
  return preguntas?.[0]?._id === preguntaId;
};

export const isLastQuestion = (preguntas: Pregunta[], preguntaId: string) => {
  return preguntas?.[preguntas.length - 1]?._id === preguntaId;
};

export const sortPreguntasById = (preguntas: Pregunta[]) => {
  return preguntas.sort((a, b) => a._id.localeCompare(b._id));
};

export const getIdPreguntasSinRespuesta = (
  preguntas: Pregunta[],
  respuestas: NSQuestion.Answers
) => {
  return preguntas
    .filter((pregunta) => !respuestas[pregunta._id])
    .map((e) => e._id);
};
