import React from "react";
import PageWrapper from "../../components/PageWrapper";
import "./index.scss";
import Espol from "../../assets/logos/EspolBlack.png";
import Mintel from "../../assets/logos/MintelBlack.png";
const Nosotros = () => {
  return (
    <div>
      <PageWrapper>
        <NosotrosText />
      </PageWrapper>
    </div>
  );
};

const NosotrosText = () => {
  return (
    <div className="container-md my-5">
      <div className="nosotros-container p-5">
        <h1 className="sobre-nosotros-title">Sobre Nosotros</h1>
        <h3>
          Ministerio de Telecomunicaciones y de la Sociedad de la Información
          &#8249;MINTEL&#8250;
        </h3>
        <p>
          El Ministerio de Telecomunicaciones y de la Sociedad de la
          Información, es el órgano rector del desarrollo de las tecnologías de
          la información y comunicación en el Ecuador, que incluyen las
          telecomunicaciones, el espectro radioeléctrico, gobierno electrónico,
          simplificación de trámites y sociedad de la información, que emite
          normativa, políticas, planes y realiza el seguimiento y evaluación de
          su implementación, que regula y controla el sector postal, coordinando
          acciones con los actores de los sectores estratégicos para garantizar
          el acceso igualitario a los servicios y promover su uso efectivo,
          eficiente y eficaz, que asegure el avance hacia la sociedad de la
          información para el desarrollo de la población ecuatoriana.
        </p>
        <h3>Escuela Superior Politécnica del Litoral &#8249;ESPOL&#8250;</h3>
        <p>
          La Escuela Superior Politécnica del Litoral tiene como misión cooperar
          con la sociedad para mejorar la calidad de vida y promover el
          desarrollo sostenible y equitativo, a través de formación profesional
          íntegra y competente, investigación e innovación.
        </p>
        <h3>
          Marco de Referencia de Competencias Digitales para el Ecuador
          &#8249;MaRCDE&#8250;
        </h3>
        <ul>
          <li>
            El MaRCDE es una referencia común para impulsar el Talento Digital
            del Ecuador.
          </li>
          <li>
            Parte de marcos validados internacionales para generar una propuesta
            contextualizada para el país.
          </li>
          <li>
            Facilita el diagnóstico de las competencias digitales y el debate
            sobre la transformación digital.
          </li>
          <li>
            La seguridad y la resolución de problemas son elementos importantes
            y transversales en cada área de competencia del MaRCDE.
          </li>
        </ul>
        <div className="image-container">
          <img
            alt="Mintel"
            height="150px"
            src={Mintel}
            width="auto"
          ></img>
          <img
            alt="Espol"
            height="75px"
            src={Espol}
            width="auto"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
