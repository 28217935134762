import React from "react";
import { useNavigate } from "react-router-dom";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import { useAppSelector } from "../../redux/store";
import Button from "../Button";
import CardList from "../CardList";
import "./index.scss";
const EvaluacionContainer = () => {
  const { area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const navigate = useNavigate();
  return (
    <div className="evaluacion-container container pt-5 px-0">
      <div className="px-2">
        <p>
          ¡Felicitaciones! Usted ha completado la evaluación del área: {area}
        </p>
        <p>
          Pon a prueba tus capacidades digitales en todas las áreas y obtén tu
          certificado de Evaluación de Competencias Digitales
        </p>
        <CardList completedTest={true}></CardList>
      </div>
      <div className="evaluacion-container-footer">
        <Button
          radius={15}
          onClick={() => {
            navigate("/resultados");
          }}
        >
          Obtener Resultados
        </Button>
      </div>
    </div>
  );
};

export default EvaluacionContainer;
