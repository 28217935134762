import About from "./About";
import Competence from "./Competence";
import Nosotros from "./Nosotros";
import Error404 from "./Error404";
import Home from "./Home";
import Question from "./Question";
import TestEnd from "./TestEnd";
import Result from "./Result";
import React from "react";
type Route = {
  path: string;
  element: React.FC;
  props?: any;
};

const routes: Route[] = [
  { path: "/", element: Home },
  { path: "/competence", element: Competence },
  { path: "/nosotros", element: Nosotros },
  { path: "/about", element: About },
  { path: "/evaluacion/:id", element: Question },
  { path: "/finalizar", element: TestEnd },
  { path: "/resultados/:id", element: Result },
  { path: "*", element: Error404 }
];

export default routes;
