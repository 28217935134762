import React from "react";
import "./index.scss";

interface TooltipInterface {
  title: string;
  content: string;
}

export const Tooltip = ({ title, content }: TooltipInterface) => {
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 40000);
  }, []);

  if (!show) return null;
  return (
    <div className="tooltiptext">
      <span>
        <strong>{title}</strong>
      </span>
      <br />
      {content}
    </div>
  );
};
