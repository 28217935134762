import React from "react";
import "../Option/index.css";
import {
  NSQuestion,
  Pregunta,
  questionSelector
} from "../../redux/slices/questions";
import { useAppSelector } from "../../redux/store";
import OptionImage from "./OptionImage";
import "./index.css";

interface Props {
  question: Pregunta;
  option: NSQuestion.STATE["preguntas"][number]["opciones"][number];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isMultipleOption?: boolean;
}

const Option = (props: Props) => {
  const { question, option, onChange, isMultipleOption = false } = props;
  const { respuestas } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const currentAnswer = respuestas[question._id];

  function checkSelect(idCurrentOption: string) {
    return !!currentAnswer?.includes(idCurrentOption);
  }

  function getStyle(idCurrentOption: string) {
    return checkSelect(idCurrentOption) ? "selected" : "not-selected";
  }

  return (
    <label className="col-md-3 py-2">
      <div
        className={`card p-3 h-100 d-flex justify-content-between current current-${getStyle(
          option._id
        )}`}
      >
        <div className="text-center mb-2">
          {option.imagen ? <OptionImage option={option} /> : <></>}
          <p>{option.texto}</p>
        </div>
        <input
          checked={checkSelect(option._id)}
          name="opciones"
          type={isMultipleOption ? "checkbox" : "radio"}
          value={option._id}
          onChange={onChange}
        ></input>
      </div>
    </label>
  );
};

export default Option;
