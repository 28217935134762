export declare namespace NSPersona {
  type ID = string;
  type STATE = {
    fetching: boolean;
    persona: Persona | undefined;
  };
}

export type Persona = {
  cedula?: string;
  nombre?: string;
  apellido?: string;
  telefono?: string;
  correo?: string;
  edad: number;
  genero: string;
  formacion: string;
  nacionalidad: string;
  provincia: string;
  canton: string;
  parroquia: string;
  etnia: string;
};

export type Calificacion = {
  calificacion: number;
  area: string;
  timestamp: Date;
  nivel: number;
  respuestas: [Respuesta];
};

export type Respuesta = {
  pregunta: string;
  puntaje: number;
  respuesta: number;
  competencia: string;
};

export const INITIAL_STATE: NSPersona.STATE = {
  fetching: false,
  persona: undefined
};
