export declare namespace NSGenero {
  type STATE = {
    generos: Genero[];
    fetching: boolean;
  };
}

export type Genero = {
  genero: string;
  _id: string;
};

export const INITIAL_STATE: NSGenero.STATE = {
  generos: [],
  fetching: false
};
