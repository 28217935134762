import React from "react";
import Lottie from "../Lottie";
import "./index.scss";
import { Tooltip } from "../Tooltip";

interface PageEmbeddedProps {
  url: string;
  size?: number;
}

export const PageEmbedded = ({ url, size = 600 }: PageEmbeddedProps) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const hideSpinner = () => {
    setIsLoading(false);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center align-items-center p-5">
        <Tooltip
          content="Puedes navegar en esta sección para encontrar la respuesta"
          title="Interactúa aquí"
        />
        <iframe
          height={size}
          id="init-simulation"
          src={url}
          title="simulacion"
          width="100%"
          onLoad={hideSpinner}
        />
      </div>
      {isLoading && (
        <div className="loading">
          <Lottie />
        </div>
      )}
    </div>
  );
};
