import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { personaSelector, NSPersona } from "../../redux/slices/persona";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";
import { useAppSelector } from "../../redux/store";
import "./index.scss";
import { AiOutlineDownload } from "react-icons/ai";
import { ResultContent } from "./Content";
import { getResultados } from "./auxiliar";

const Result = () => {
  const { persona } = useAppSelector(personaSelector) as NSPersona.STATE;
  const { area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const [resultado, setResultado] = React.useState(undefined);
  const resultRef = React.useRef<HTMLDivElement>(null);
  const { id: idResultado = "" } = useParams();
  const navigate = useNavigate();
  const pdfRef = useRef(null);
  const courseListRef = useRef<any>(null);
  const carrouselRef = useRef<any>(null);

  React.useEffect(() => {
    if (!persona && !area) {
      navigate("/");
    }
  }, [area, navigate, persona]);

  React.useEffect(() => {
    (async () => {
      const resultados = await getResultados(idResultado);
      setResultado(resultados);
    })();
  }, [idResultado]);

  const generatePDF = () => {
    if (courseListRef.current && carrouselRef.current) {
      courseListRef.current.style.display = "block";
      carrouselRef.current.style.display = "none";
      if (pdfRef.current) {
        const pdf = new jsPDF("p", "mm", "a4");
        const content = pdfRef.current;

        html2canvas(content).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
          pdf.save("Resultado - Competencias Digitales.pdf");
        });
      }
      courseListRef.current.style.display = "none";
      carrouselRef.current.style.display = "block";
    }
  };

  return (
    <div
      className="main-container"
      ref={resultRef}
    >
      <div className="main-title">
        <h1 className="text-blue text-center p-5 col-12">
          Evaluación de Competencias Digitales <br />
          Área: {area}
        </h1>
        <Button
          className="download-button"
          color="green"
          radius={15}
          textColor="white"
          onClick={generatePDF}
        >
          <span>
            <AiOutlineDownload
              className="mx-1"
              size={15}
            />
            Descargar Resultados
          </span>
        </Button>
      </div>
      <div
        className="container"
        ref={pdfRef}
      >
        <ResultContent
          carrouselRef={carrouselRef}
          courseListRef={courseListRef}
          resultado={resultado}
        />
      </div>
    </div>
  );
};

export default Result;
