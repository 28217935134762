import { close } from "../../redux/slices/modal";
import { useAppDispatch } from "../../redux/store";
import Button from "../Button";

export const StatusModal = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="container mb-5">
      <h3 className="text-center">La evaluación ha terminado</h3>
      <section className="text-center mt-4">
        <p>
          El horario programado para esta evaluación ha finalizado. Por favor
          pongase en contacto con las autoridades pertinentes para más
          indicaciones
        </p>
      </section>
      <div className="d-flex justify-content-center ecua-button-container">
        <Button
          className="m-1 ecua-navigation"
          color="blue"
          radius={15}
          textColor="white"
          onClick={() => dispatch(close())}
        >
          Aceptar
        </Button>
      </div>
    </div>
  );
};
