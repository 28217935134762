import React from "react";
import "./index.scss";

type AvailableColors =
  | "red"
  | "blue"
  | "yellow"
  | "white"
  | "green"
  | "gray"
  | ""
  | undefined;

type Props = {
  color?: AvailableColors;
  textColor?: "black" | "white";
  radius?: number;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  React.AriaAttributes;

const Button: React.FC<Props> = (props) => {
  const { color, textColor, children, radius, onClick, className, ...rest } =
    props;
  const [coords, setCoords] = React.useState({ x: -1, y: -1 });
  const [isRippling, setIsRippling] = React.useState(false);

  React.useEffect(() => {
    if (coords.x !== -1 && coords.y !== -1) {
      setIsRippling(true);
      setTimeout(() => setIsRippling(false), 300);
    } else setIsRippling(false);
  }, [coords]);

  React.useEffect(() => {
    if (!isRippling) setCoords({ x: -1, y: -1 });
  }, [isRippling]);

  return (
    <button
      className={`${setButtonColor(
        color
      )} ecua-button button-disabled ${className}`}
      style={{
        color: textColor,
        borderRadius: `${radius ? radius : 2}px`
      }}
      onClick={(e) => {
        const rect = (e.target as Element).getBoundingClientRect();
        setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
        onClick && onClick(e);
      }}
      {...rest}
    >
      {isRippling ? (
        <span
          className={`${
            color === "white" ? "button-ripple-white" : "button-ripple"
          }`}
          style={{
            left: coords.x,
            top: coords.y
          }}
        />
      ) : (
        ""
      )}
      <span className="content">{children}</span>
    </button>
  );

  function setButtonColor(color: AvailableColors) {
    switch (color) {
      case "red": {
        return "button-red";
      }
      case "blue": {
        return "button-blue";
      }
      case "yellow": {
        return "button-yellow";
      }
      case "white": {
        return "button-white";
      }
      case "green": {
        return "button-green";
      }
      case "gray": {
        return "button-gray";
      }
      default: {
        return "button-yellow";
      }
    }
  }
};

export default React.memo(Button);
