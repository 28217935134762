import React from "react";
import "./index.scss";
const Offline = () => {
  return (
    <div className="ecua-offline">
      <h1 className="text-center">¡Has perdido tu conexión a Internet!</h1>
      <p className="text-center">Intenta reiniciar tu navegador.</p>
    </div>
  );
};

export default Offline;
