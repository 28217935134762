import React from "react";

const Option = ({ name, option, isMultiple, onChange }: Props) => {
  const text = option.optionText;
  return (
    <label style={{ display: "block", marginTop: "8px", marginBottom: "8px" }}>
      <input
        // checked={checkSelect(option._id)}
        name={name}
        style={{ marginRight: "4px" }}
        type={isMultiple ? "checkbox" : "radio"}
        value={text}
        onChange={onChange}
      />
      <span>{text}</span>
    </label>
  );
};

interface Props {
  option: { optionText: string; correct: boolean };
  isMultiple: boolean;
  name: string;
  onChange: (e: any) => void;
}

export default Option;
