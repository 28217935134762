import "./index.scss";
import image from "../../../assets/lupa2.png";
import { useAppDispatch } from "../../../redux/store";
import { open, setContent } from "../../../redux/slices/modal";
import DisplayImageModal from "../../../components/DisplayImageModal";

interface Props {
  src: string;
  alt: string;
  canRenderTool: boolean;
}

export const MultimediaImage = ({ src, alt }: Props) => {
  const dispatch = useAppDispatch();
  const imageSrc = process.env.REACT_APP_BACK_MULTIMEDIA + src;

  const handleClick = (srcImage: string) => {
    dispatch(setContent(<DisplayImageModal image={srcImage} />));
    dispatch(open());
  };

  return (
    <div
      className="question-image tooltipImage"
      style={{ position: "relative" }}
    >
      <span
        className="tooltiptext"
        style={{ width: "100%" }}
      >
        Da click sobre la imágen para aumentar su tamaño
      </span>
      <img
        alt={alt}
        className="question-image"
        src={imageSrc}
        style={{ width: 500, cursor: "pointer" }}
        onClick={() => handleClick(imageSrc)}
      />
      <div
        className="lupa animated flash"
        style={{
          backgroundColor: "#026ca1",
          paddingBottom: 4,
          paddingLeft: 12,
          borderBottomLeftRadius: 60
        }}
        onClick={() => handleClick(imageSrc)}
      >
        <img
          alt="Lupa"
          height={40}
          src={image}
          width={40}
        />
      </div>
    </div>
  );
};
