import React from "react";
import { ReactComponent as Copa } from "../../assets/result/copa.svg";
import shuttle from "../../assets/result/shuttle.png";
import { Persona } from "../../redux/slices/persona";
import IconText from "../IconText";
import Level from "../Level";

interface DescripcionNivelInterface {
  persona?: Persona;
  nivel: number;
}

const DescripcionNivel = ({ persona, nivel }: DescripcionNivelInterface) => {
  function setColorCopa(nivel: number) {
    switch (nivel) {
      case 1:
        return "copa-bronze";
      case 2:
        return "copa-plata";
      case 3:
        return "copa-oro";
      default:
        return "copa-bronze";
    }
  }

  function buildText(nivel: number) {
    switch (nivel) {
      case 0: {
        const labels = [
          "Tienes un nivel pre-básico de competencias digitales",
          "No tienes autonomía digital",
          "Necesitas ayuda de terceros para resolver problemas sencillos",
          "Necesitas ayuda de terceros para realizar tareas sencillas que requieran competencias digitales"
        ];
        return labels.map((value) => (
          <IconText
            color="red"
            key={value}
            error
          >
            {value}
          </IconText>
        ));
      }
      case 1: {
        const labels = [
          "No tienes autonomía digital",
          "Puede que necesites ayuda de terceros para resolver problemas sencillos",
          "Necesitas ayuda de terceros para realizar tareas sencillas que requieran competencias digitales"
        ];
        return labels.map((value) => (
          <IconText
            color="orange"
            key={value}
            error
          >
            {value}
          </IconText>
        ));
      }
      case 2: {
        const labels = [
          "Tienes un nivel intermedio de autonomía digital",
          "Puedes resolver problemas específicos por tí mismo",
          "Puedes realizar tareas específicas que requieran competencias digitales"
        ];
        return labels.map((value) => (
          <IconText
            color="yellow"
            key={value}
          >
            {value}
          </IconText>
        ));
      }
      case 3: {
        const labels = [
          "Tienes completa autonomía digital",
          "Puedes resolver fácilmente problemas por tí mismo",
          "Puedes realizar fácilmente tareas que requieran competencias digitales"
        ];
        return labels.map((value) => (
          <IconText
            color="green"
            key={value}
          >
            {value}
          </IconText>
        ));
      }
      default: {
        const labels = [
          "Tienes un nivel pre-básico de competencias digitales",
          "No tienes autonomía digital",
          "Necesitas ayuda de terceros para resolver problemas sencillos",
          "Necesitas ayuda de terceros para realizar tareas sencillas que requieran competencias digitales"
        ];
        return labels.map((value) => (
          <IconText
            color="red"
            key={value}
            error
          >
            {value}
          </IconText>
        ));
      }
    }
  }

  function renderBadge(nivel: number) {
    if (!nivel) {
      return (
        <img
          alt="Shuttle"
          height={100}
          src={shuttle}
          width={100}
        />
      );
    }
    return <Copa className={`${setColorCopa(nivel)}`} />;
  }

  function renderCopy(nivel: number, persona?: Persona) {
    const { nombre = "", apellido = "" } = persona || {};
    return nivel ? (
      <h5 className="fw-normal">
        ¡Felicidades {nombre} {apellido}!
      </h5>
    ) : (
      <h5 className="fw-normal">
        ¡Puede mejorar {nombre} {apellido}!
      </h5>
    );
  }

  return (
    <div className="row">
      <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
        {renderCopy(nivel, persona)}
        {renderBadge(nivel)}
      </div>
      <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
        <h5 className="fw-normal">Su nivel es:</h5>
        <Level nivel={nivel} />
      </div>
      <div className="col-md-4">{buildText(nivel)}</div>
    </div>
  );
};

export default DescripcionNivel;
