import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { INITIAL_STATE, NSQuestion, Pregunta } from "./_namespace";
import { fetchPrueba, fetchQuestion } from "./_fetchers";
export const questionSlice = createSlice({
  name: "question",
  initialState: INITIAL_STATE,
  reducers: {
    cleanQuestion(state) {
      state.area = INITIAL_STATE.area;
      state.test = INITIAL_STATE.test;
      state.active = INITIAL_STATE.active;
      state.competencia = INITIAL_STATE.competencia;
      state.fetching = false;
      state.indexPregunta = INITIAL_STATE.indexPregunta;
      state.respuestas = INITIAL_STATE.respuestas;
      state.preguntas = INITIAL_STATE.preguntas;
      state.start_date = INITIAL_STATE.start_date;
      state.end_date = INITIAL_STATE.end_date;
    },
    setCompetences(state, action: PayloadAction<string>) {
      state.area = action.payload;
    },
    setOption(state, action: PayloadAction<NSQuestion.Answers>) {
      state.respuestas = { ...state.respuestas, ...action.payload };
    },
    setNetworkConnectivity(state, action: PayloadAction<number | undefined>) {
      state.networkConnectivity = action.payload;
    },
    setStartTest(state, action: PayloadAction<Date>) {
      state.start_date = action.payload;
    },
    setEndTest(state, action: PayloadAction<Date>) {
      state.end_date = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestion.fulfilled, (state) => {
      state.fetching = false;
    });
    builder.addCase(fetchQuestion.rejected, (state) => {
      state.fetching = false;
    });
    builder.addCase(fetchQuestion.pending, (state) => {
      state.fetching = true;
    });
    //GENERAR PRUEBA
    builder.addCase(
      fetchPrueba.fulfilled,
      (
        state,
        action: PayloadAction<{ preguntas: Pregunta[]; test: string }>
      ) => {
        state.preguntas = action.payload.preguntas;
        state.test = action.payload.test;
        state.active = 1;
        state.fetching = false;
      }
    );
    builder.addCase(fetchPrueba.rejected, (state) => {
      state.fetching = false;
      state.active = 0;
    });
    builder.addCase(fetchPrueba.pending, (state) => {
      state.fetching = true;
    });
  }
});
