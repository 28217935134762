import "./index.scss";

interface Props {
  link: string;
  label: string;
  codigo: number;
  codigoCompetencia: number;
}

export const TinyCurso = ({
  link,
  label,
  codigo,
  codigoCompetencia
}: Props) => {
  return (
    <li className="ecua-wrapper">
      <div>
        <p className="">
          Competencia {`${codigo}.${codigoCompetencia}: `}
          {label}
          <br />
          Enlace al curso:
          {link}
        </p>
      </div>
    </li>
  );
};
