import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useAppSelector } from "../../redux/store";
import { questionSelector, NSQuestion } from "../../redux/slices/questions";

import "./index.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top" as const
    }
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100
    }
  }
};

type Props = {
  nota: number;
  promedioEcuatoriano: number;
};

const VerticalChart: React.FC<Props> = (props) => {
  const { nota, promedioEcuatoriano } = props;
  const { area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  const labels = [area];

  const data = {
    labels,
    datasets: [
      {
        label: "Mis Resultados",
        data: [calcularNota(nota)],
        backgroundColor: "#477641"
      },
      {
        label: "Promedio Ecuatoriano",
        data: [calcularNota(promedioEcuatoriano)],
        backgroundColor: "#FFDD47"
      }
    ]
  };
  return (
    <div className="height-90">
      <Bar
        data={data}
        options={options}
      />
    </div>
  );

  function calcularNota(nota: number) {
    return (100 * nota) / 42;
  }
};

export default VerticalChart;
