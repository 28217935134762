import React from "react";
import "./index.scss";
import { AiOutlineClose } from "react-icons/ai";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { modalSelector, NSModal, close } from "../../redux/slices/modal";
type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Modal: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const modal = useAppSelector(modalSelector) as NSModal.STATE;
  const { open, content } = modal;
  const { className } = props;
  const [contentState, setContent] = React.useState<JSX.Element | undefined>(
    undefined
  );
  React.useEffect(() => {
    const ModalJSX = () => content;
    setContent(ModalJSX);
  }, [content]);
  return (
    <>
      <div
        className={`modal-background ${className ? className : ""}`}
        style={{
          display: open ? "flex" : "none"
        }}
        onClick={() => {
          dispatch(close());
        }}
      ></div>
      <div
        className="ecua-modal"
        style={{
          display: open ? "block" : "none"
        }}
      >
        <div className="ecua-modal-close">
          <AiOutlineClose
            onClick={() => {
              dispatch(close());
            }}
          />
        </div>
        <div>{contentState ? contentState : ""}</div>
      </div>
    </>
  );
};

export default React.memo(Modal);
