import mintelapi from "../../../mintelapi";

export const submitEncuesta = async (
  idEncuesta: string,
  respuestas: Record<string, string[]>
) => {
  const data = Object.entries(respuestas).map(([pregunta, respuestas]) => ({
    pregunta,
    respuestas
  }));
  await mintelapi.post("/resultados-encuestas/save", {
    encuesta: idEncuesta,
    respuestas: data
  });
};
