import * as Yup from "yup";
import { validarCedula } from "../utils";
import { REGEX_ONLY_TEXT } from "../utils/constants";
import {
  formatOnlyText,
  formatRequired,
  formatValue
} from "../utils/formatFieldsForMessage";
import { TipoUsuario } from "../components/FormModal/enums";

export const personalFormValidationSchema = Yup.object({
  cedula: Yup.string().when("showCedula", (value) => {
    const [show] = value;
    if (parseInt(show)) {
      return Yup.string().test(
        "validar-cedula",
        "El número de cédula no es correcto",
        validarCedula
      );
    }
    return Yup.string().optional();
  }),
  nombre: Yup.string()
    .matches(REGEX_ONLY_TEXT, formatOnlyText("nombre"))
    .required(formatRequired("El nombre")),
  apellido: Yup.string()
    .matches(REGEX_ONLY_TEXT, formatOnlyText("apellido"))
    .required(formatRequired("El apellido")),
  edad: Yup.number()
    .typeError(formatValue("La edad"))
    .required(formatRequired("La edad", false)),
  genero: Yup.string().required(formatRequired("El genero")),
  formacion: Yup.string().required(formatRequired("La formacion", false)),
  localidad: Yup.string().required(formatRequired("La localidad", false)),
  nacionalidad: Yup.string().required(formatRequired("La nacionalidad", false)),
  provincia: Yup.string().when("nacionalidad", (value) => {
    const [nacionalidad] = value;
    if (nacionalidad === "Ecuatoriano") {
      return Yup.string().required(formatRequired("La provincia", false));
    }
    return Yup.string().optional();
  }),
  canton: Yup.string().when("nacionalidad", (value) => {
    const [nacionalidad] = value;
    if (nacionalidad === "Ecuatoriano") {
      return Yup.string().required(formatRequired("El cantón", false));
    }
    return Yup.string().optional();
  }),
  parroquia: Yup.string().when("nacionalidad", (value) => {
    const [nacionalidad] = value;
    if (nacionalidad === "Ecuatoriano") {
      return Yup.string().required(formatRequired("La parroquia", false));
    }
    return Yup.string().optional();
  }),
  etnia: Yup.string().required(formatRequired("La etnia", false)),

  tipo: Yup.string().required("Este campo es obligatorio"),
  institucion: Yup.string().when("tipo", (value) => {
    const [tipo] = value;
    if (tipo !== TipoUsuario.Ciudadano) {
      return Yup.string().required(
        formatRequired("La organización a la que pertenece", false)
      );
    }
    return Yup.string().optional();
  })
});
