import React from "react";
import "./index.scss";
import Joyride from "react-joyride";

export class Tour extends React.Component {
  state = {
    steps: [
      {
        title: "Bienvenido a la evaluación de competencias digitales",
        target: "#question-box",
        disableBeacon: true,
        content:
          "En esta sección se mostrará la pregunta junto a los recursos necesarios para responderla"
      },
      {
        target: "#question-options",
        content:
          "En esta sección usted podrá ingresar sus respuestas. Estas pueden ser de selección múltiple o ingreso de texto. Si la opción contiene una imágen que no es posible visualizar desde el recuadro, por favor, de clic en la lupa a la derecha para maximizar su tamaño"
      },
      {
        target: "#back-button",
        content:
          'Use el botón "Atrás" para navegar a la pregunta anterior cada vez que lo necesite'
      },
      {
        target: "#skip-button",
        content:
          'Use el botón "Saltar" únicamente cuando desconozca la respuesta'
      },
      {
        target: "#next-button",
        content:
          'Si responde la pregunta el botón "Siguiente" se habilitará y podrá presionarlo para guardar su respuesta.'
      },
      {
        target: ".container-bar",
        title: "Progreso",
        content:
          "Esta barra mostrará tu progreso en la evaluación. Las preguntas sin respuesta se marcarán de color rojo"
      },
      {
        target: ".container-bar",
        title: "Navegación",
        content:
          "Puedes navegar hacia cualquier pregunta haciendo click desde esta barra de progreso"
      },
      {
        title: "Puedes aumentar el tamaño de las imágenes",
        target: ".question-image",
        content:
          "Si la imágen no logra apreciarse correctamente, puedes aumentar su tamaño dando click sobre ella o en la lupa que se encuentra en la esquina superior derecha"
      },
      {
        target: ".test-container",
        title: "¡Está listo!",
        content:
          "Ha llegado al final de la guía, está listo para comenzar la evaluación"
      }
    ],
    config: {
      back: "Atrás",
      close: "Cerrar",
      last: "Continuar",
      next: "Siguiente",
      open: "Abrir el dialogo",
      skip: "Saltar"
    }
  };

  render() {
    const { steps, config } = this.state;

    return (
      <div className="app">
        <Joyride
          continuous={true}
          locale={config}
          steps={steps}
          styles={{
            options: {
              primaryColor: "#000"
            },
            buttonNext: {
              backgroundColor: "#026ca1",
              borderRadius: 4,
              color: "#fff"
            },
            beacon: {
              display: "none"
            }
          }}
        />
      </div>
    );
  }
}

export default Tour;
