export declare namespace NSEstadisticas {
  type STATE = {
    finish_count: number;
    preguntas_respondidas?: number;
    preguntas_sin_respuesta?: number;
    ids_preguntas_sin_respuesta?: string[];
    estadisticasPreguntas: EstadisticasPreguntas;
  };

  type EstadisticasPreguntas = Record<string, MetricasPreguntas>;

  interface MetricasPreguntas {
    atras: number;
    siguiente: number;
    saltar: number;
    duracion: number[];
    timestamps: { start?: Date; end?: Date }[];
    vistas: number;
  }
}

export const INITIAL_STATE: NSEstadisticas.STATE = {
  finish_count: 0,
  preguntas_respondidas: 0,
  preguntas_sin_respuesta: 0,
  ids_preguntas_sin_respuesta: [],
  estadisticasPreguntas: {}
};
