import React from "react";
import espol from "../../../assets/logos/Espol.png";
import mintel from "../../../assets/logos/MintelWhite.png";
import { useAppSelector } from "../../../redux/store";
import { questionSelector, NSQuestion } from "../../../redux/slices/questions";
import { ProgressBar } from "../../../components/ProgressBar";
import "./index.scss";

type Props = {
  complete?: boolean;
  header?: string;
};

const QuestionHeader: React.FC<Props> = (props) => {
  const { complete, header } = props;
  const { area } = useAppSelector(questionSelector) as NSQuestion.STATE;
  return (
    <div className="ecua-question-header">
      <div className="ecua-rectangle-header"></div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              alt="mintel"
              height={150}
              src={mintel}
            ></img>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img
              alt="espol"
              height={75}
              src={espol}
            ></img>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-around flex-row my-4">
        <h1 className="text-white">{header ? header : `Área: ${area}`}</h1>
      </div>
      <div className="d-flex align-items-center justify-content-around flex-row my-4 container-bar">
        <ProgressBar complete={complete} />
      </div>
    </div>
  );
};

export default QuestionHeader;
