import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";

export const fetchConfiguration = createAsyncThunk(
  "config/getConfiguration",
  async () => {
    const response = await mintelapi.get("/config");
    return response.data;
  }
);
