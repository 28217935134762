import React from "react";
import "./index.scss";

type Props = {
  nivel: number;
};

const Level: React.FC<Props> = (props) => {
  const { nivel } = props;

  return <p className={getClase(nivel)}>{getNivel(nivel)}</p>;

  function getNivel(nivel: number) {
    switch (nivel) {
      case 0:
        return "Inicial";
      case 1:
        return "Básico";
      case 2:
        return "Intermedio";
      case 3:
        return "Avanzado";
      default:
        return "Inicial";
    }
  }

  function getClase(nivel: number) {
    switch (nivel) {
      case 0:
        return "nivel-inicial";
      case 1:
        return "nivel-basico";
      case 2:
        return "nivel-intermedio";
      case 3:
        return "nivel-avanzado";
      default:
        return "nivel-inicial";
    }
  }
};

export default Level;
