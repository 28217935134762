export const scroll = (id: string) => {
  const section = document.querySelector(id);
  section?.scrollIntoView({ behavior: "smooth", block: "start" });
};

export function validarCedula(cedula: any) {
  var cad = cedula?.trim() || "";
  var total = 0;
  var longitud = cad.length;
  var longcheck = longitud - 1;

  if (cad !== "" && longitud === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        var aux = cad.charAt(i) * 2;
        if (aux > 9) aux -= 9;
        total += aux;
      } else {
        total += parseInt(cad.charAt(i));
      }
    }

    total = total % 10 ? 10 - (total % 10) : 0;

    // eslint-disable-next-line eqeqeq
    if (cad.charAt(longitud - 1) == total) {
      return true;
    } else {
      return false;
    }
  } else return false;
}
