import React from "react";
import { open, setContent } from "../../../redux/slices/modal";
import { NSQuestion, Pregunta } from "../../../redux/slices/questions";
import { useAppDispatch } from "../../../redux/store";
import {
  setFinishCount,
  setFinishPregunta
} from "../../../redux/slices/estadisticas/_actions";
import { WarningModal } from "../../../components/WarningModal";
import FormModal from "../../../components/FormModal";
import Button from "../../../components/Button";

interface EndButtonInterface {
  display: boolean;
  respuestas: NSQuestion.Answers;
  preguntas: Pregunta[];
  preguntaId: string;
  onClick: () => void;
}

export const FinishButton = ({
  display,
  respuestas,
  preguntas,
  preguntaId,
  onClick
}: EndButtonInterface) => {
  const dispatch = useAppDispatch();

  const getQuestionsWithoutAnswers = () => {
    return preguntas.length - Object.entries(respuestas).length;
  };

  const handleFinished = () => {
    onClick();
    const remaining = getQuestionsWithoutAnswers();
    if (remaining) {
      dispatch(
        setContent(<WarningModal questionsWithoutAnswers={remaining} />)
      );
      dispatch(open());
    } else {
      dispatch(setContent(<FormModal />));
      dispatch(open());
    }
    dispatch(setFinishPregunta(preguntaId));
    dispatch(setFinishCount());
  };

  return display ? (
    <Button
      color={"yellow"}
      id="finish-button"
      radius={15}
      textColor={"black"}
      onClick={handleFinished}
    >
      Finalizar
    </Button>
  ) : (
    <></>
  );
};
