export const addAnswer = (
  nuevaRespuesta: string,
  respuestas: Record<string, string[]>,
  preguntaO: any,
  isMultipleOptionQuestion: boolean
) => {
  const pregunta = preguntaO?.questionText;
  const respuestasAnteriores = respuestas[pregunta];
  let finalRespuestas = [];
  if (respuestasAnteriores?.includes(nuevaRespuesta)) {
    finalRespuestas = respuestasAnteriores.filter(
      (respuesta) => respuesta !== nuevaRespuesta
    );
  } else {
    finalRespuestas = isMultipleOptionQuestion
      ? [...(respuestasAnteriores || []), nuevaRespuesta]
      : [nuevaRespuesta];
  }
  return finalRespuestas;
};
