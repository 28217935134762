export type NSEncuestaSTATE = {
  encuesta: Encuesta;
  fetching: boolean;
};

export interface Encuesta {
  _id: string;
  nombre: string;
  descripcion: string;
  institucion: Institucion;
  preguntas: NSEncuestaPregunta[];
  mode: NSEncuestaMode | undefined;
  status: number;
}

export interface Institucion {
  name: string;
  logo: string;
}

export interface NSEncuestaPregunta {
  questionText: string;
  questionType: NSEncuestaPreguntaType;
  options: NSEncuestaOption[];
}

export enum NSEncuestaPreguntaType {
  text = "textAnswer",
  options = "multipleChoice"
}

export interface NSEncuestaOption {
  optionText: string;
  correct: boolean;
}

export enum NSEncuestaMode {
  Before = "antes",
  After = "luego"
}

export const INITIAL_STATE: NSEncuestaSTATE = {
  encuesta: {
    _id: "",
    nombre: "",
    descripcion: "",
    institucion: { name: "", logo: "" },
    preguntas: [],
    mode: undefined,
    status: 1
  },
  fetching: false
};
