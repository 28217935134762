import React from "react";
import { Step, handleSteps } from "./utils";
import Presentacion from "./Presentacion";
import Preguntas from "./Preguntas";
import Final from "./Final";
import "./styles/index.css";
import { useAppSelector } from "../../redux/store";
import {
  Encuesta,
  NSEncuestaSTATE,
  encuestaSelector
} from "../../redux/slices/encuesta";

const EncuestaComponent = ({ nextIdQuestion, isFinal }: Props) => {
  const [state, dispatch] = React.useReducer<any>(handleSteps, {
    component: Step.Presentacion
  });
  const { encuesta = {} } = useAppSelector(encuestaSelector) as NSEncuestaSTATE;
  const { logo = "", name = "" } = (encuesta as Encuesta).institucion || {};

  const components = {
    [Step.Presentacion]: (
      <Presentacion
        nameInstitucion={name}
        stateDispatch={dispatch}
      />
    ),
    [Step.Preguntas]: <Preguntas dispatch={dispatch} />,
    [Step.Final]: (
      <Final
        isFinal={isFinal}
        nextIdQuestion={nextIdQuestion}
      />
    )
  };

  return (
    <div className="presentation-container">
      <div style={{ display: "flex", justifyContent: "end" }}>
        <img
          alt="Presentacion Logo"
          src={process.env.REACT_APP_BACK_MULTIMEDIA + logo}
          width={150}
        />
      </div>
      {components[(state as any).component as Step]}
    </div>
  );
};

interface Props {
  nextIdQuestion?: string;
  isFinal?: boolean;
}

export default EncuestaComponent;
