export declare namespace NSQuestion {
  type PruebaParams = {
    area: string;
    networkConnectivity: number;
  };

  type STATE = {
    indexPregunta: number;
    networkConnectivity: number | undefined;
    area: string;
    test: string;
    competencia: string;
    preguntas: Pregunta[];
    start_date: Date | undefined;
    end_date: Date | undefined;
    active: number;
    fetching: boolean;
    respuestas: Answers;
  };

  type Answers = Record<string, string[] | string | undefined>;
}

export type Pregunta = {
  _id: string;
  enunciado: string;
  area: string;
  multimedia: Multimedia;
  simulacion?: string;
  competencia: string;
  dificultad: number;
  opciones: Opcion[];
  respuesta?: string;
  tipoRespuesta?: TipoRespuesta;
};

export type TipoRespuesta = "opciones" | "texto" | "opciones_multiple";

export enum Areas {
  informacion = "Información",
  contenidos_digitales = "Creación de contenidos digitales",
  comunicacion = "Comunicación y Colaboración"
}

export type Opcion = {
  texto: string;
  puntaje: number;
  imagen?: string;
  correcto?: boolean;
  _id: string;
};

export type Multimedia = {
  tipoMultimedia: string;
  url: string;
};

export const INITIAL_STATE = {
  indexPregunta: 0,
  start_date: undefined,
  end_date: undefined,
  area: "",
  test: "",
  competencia: "",
  networkConnectivity: undefined,
  preguntas: [],
  active: 1,
  respuestas: {},
  fetching: false
} as NSQuestion.STATE;
