import mintelapi from "../../mintelapi";
import { fetchPersona } from "../../redux/slices/persona";
import { store } from "../../redux/store";

export const submitResultados = async (resultados: any) => {
  const response = await mintelapi.post("/respuestas/submit", {
    resultados: { ...resultados, end_date: new Date() }
  });
  const { idPersona, idResultado } = response.data;
  await store.dispatch(fetchPersona(idPersona));
  return idResultado;
  // await store.dispatch(fetchResultado(idResultado));
};
