import mintelapi from "../../../mintelapi";
import { createAsyncThunk } from "@reduxjs/toolkit";

//TODO esperar al pre-diseño de la base de datos
export const fetchProvincias = createAsyncThunk(
  "question/getQuestion",
  async () => {
    const response = await mintelapi.get("/provincias");
    return response.data;
  }
);
