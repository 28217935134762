import { useNavigate } from "react-router-dom";
import { Pregunta } from "../../../redux/slices/questions";
import { getBackID } from "../../../utils/preguntas";
import { useDispatch } from "react-redux";
import {
  setBackCount,
  setFinishPregunta,
  setViewPregunta
} from "../../../redux/slices/estadisticas/_actions";
import Button from "../../../components/Button";

interface BackButtonInterface {
  disabled: boolean;
  preguntaId: string;
  preguntas: Pregunta[];
}

export const BackButton = ({
  disabled,
  preguntaId,
  preguntas
}: BackButtonInterface) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = () => {
    const id = getBackID(preguntas, preguntaId);
    navigate(`/evaluacion/${id}`);
    dispatch(setFinishPregunta(preguntaId));
    dispatch(setBackCount(preguntaId));
    dispatch(setViewPregunta(id));
  };

  return (
    <Button
      color="white"
      disabled={disabled}
      id="back-button"
      radius={15}
      onClick={handleBack}
    >
      Atrás
    </Button>
  );
};
