import React from "react";
import Enviado from "../../assets/enviado.png";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { close } from "../../redux/slices/modal";
import { useNavigate } from "react-router-dom";

const Final = ({ nextIdQuestion, isFinal }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleContinue = () => {
    if (nextIdQuestion) {
      dispatch(close());
      navigate(`/evaluacion/${nextIdQuestion}`);
    } else if (isFinal) {
      navigate("/resultados");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 12,
        marginBottom: 30
      }}
    >
      <div>
        <img
          alt="Enviado correctamente"
          src={Enviado}
          style={{ marginLeft: "auto", marginRight: "auto" }}
          width="200px"
        />
      </div>
      <div>
        <p>
          Gracias por llenar esta encuesta. ¡Sus respuestas se han enviado con
          éxito!
          <br />
          Al dar clic en &quot;Continuar&quot; volverá a la evaluación de
          Competencias Digitales
        </p>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Button
            color="blue"
            radius={20}
            textColor="white"
            onClick={handleContinue}
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

interface Props {
  nextIdQuestion?: string;
  isFinal?: boolean;
}

export default Final;
