import { createAsyncThunk } from "@reduxjs/toolkit";
import mintelapi from "../../../mintelapi";

export const fetchEncuesta = createAsyncThunk(
  "encuesta/getActive",
  async () => {
    const response = await mintelapi.get("/encuestas/getActiveEncuesta");
    return response.data;
  }
);
