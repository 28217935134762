import React from "react";
import { useDispatch } from "react-redux";
import {
  NSQuestion,
  Pregunta,
  questionSelector,
  setOption
} from "../../../redux/slices/questions";
import { useAppSelector } from "../../../redux/store";
import Option from "../../../components/Option";

interface Props {
  pregunta: Pregunta;
}

export const MultipleOptions = ({ pregunta }: Props) => {
  const { respuestas } = useAppSelector(questionSelector) as NSQuestion.STATE;

  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idAnswer = event.target.value;
    const lastAnswers = (respuestas[pregunta._id] || []) as string[];
    if (isAlreadySelect(idAnswer, lastAnswers)) {
      removeOption(lastAnswers, idAnswer);
    } else {
      addOption(lastAnswers, idAnswer);
    }
  };

  const isAlreadySelect = (optionSelected: string, answers: string[]) => {
    return answers.some((e) => e === optionSelected);
  };

  const addOption = (lastAnswers: string[], optionSelected: string) => {
    dispatch(setOption({ [pregunta._id]: [...lastAnswers, optionSelected] }));
  };

  const removeOption = (lastAnswers: string[], optionSelected: string) => {
    const filteredOptions = lastAnswers.filter((e) => e !== optionSelected);
    dispatch(setOption({ [pregunta._id]: filteredOptions }));
  };

  return (
    <div className="my-5">
      <div className="row">
        {pregunta &&
          pregunta.opciones?.map((option, index) => (
            <Option
              key={index}
              option={option}
              question={pregunta}
              isMultipleOption
              onChange={handleChange}
            />
          ))}
      </div>
    </div>
  );
};
