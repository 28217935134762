import React from "react";
import { GiSpeaker } from "react-icons/gi";
import { BiStop } from "react-icons/bi";
import { PageEmbedded } from "../../../components/Simulation";
import { Pregunta } from "../../../redux/slices/questions";
import { MultimediaImage } from "../Multimedia/MultimediaImage";
import { useAppDispatch } from "../../../redux/store";
import { open, setContent } from "../../../redux/slices/modal";
import { SimulacionModal } from "../../../components/SimulacionModal";
import "./index.scss";

interface EnunciadoInterface {
  pregunta?: Pregunta;
  totalPreguntas: number;
  disableModals: boolean;
  canRenderTool: boolean;
}

export const Enunciado = ({
  pregunta,
  totalPreguntas,
  disableModals,
  canRenderTool
}: EnunciadoInterface) => {
  const synth = window.speechSynthesis;

  const dispatch = useAppDispatch();
  const [speak, setSpeak] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!disableModals && simulacion) {
      dispatch(setContent(<SimulacionModal />));
      dispatch(open());
    }
  }, [pregunta]);

  const { enunciado = "", simulacion = "", multimedia, _id } = pregunta || {};

  const alignClassSimulacion =
    simulacion && multimedia ? "col-7 simulation-container" : "";
  const alignClassMultimedia =
    simulacion && multimedia ? "col-5 multimedia-container" : "";

  return (
    <div id="question-box">
      <h4>
        {totalPreguntas && enunciado}&nbsp;
        <span
          className="speaker-container"
          key={_id}
          onClick={() => handleVoice(enunciado)}
        >
          {speak ? (
            <BiStop
              className="speaker-icon"
              key={_id}
            />
          ) : (
            <GiSpeaker
              className="speaker-icon"
              key={_id}
            />
          )}
        </span>
      </h4>

      <div className="row align-items-center resource-container">
        {multimedia && (
          <div
            className={`d-flex justify-content-center align-items-center pt-5 ${alignClassMultimedia}`}
          >
            <MultimediaImage
              alt={"Question Resources"}
              canRenderTool={canRenderTool}
              key={_id}
              src={multimedia.url}
            />
          </div>
        )}
        {simulacion && (
          <div className={alignClassSimulacion}>
            <PageEmbedded
              key={_id}
              url={simulacion}
            />
          </div>
        )}
      </div>
    </div>
  );

  function handleVoice(enunciado: string) {
    const utter = new SpeechSynthesisUtterance(enunciado);
    utter.onend = () => {
      setSpeak(false);
    };
    if (speak) {
      setSpeak(false);
      synth.cancel();
      return;
    }
    utter.lang = "es-ES";
    synth.speak(utter);
    setSpeak(true);
  }
};
