import React from "react";
import Intro from "../../components/Intro";
import PageWrapper from "../../components/PageWrapper";
import certificado from "../../assets/home/certificado-en-linea.png";
import estudiante from "../../assets/home/estudiante.png";
import recomendaciones from "../../assets/home/recomendaciones.png";
import "./index.scss";
import CardList from "../../components/CardList";
import { useAppDispatch } from "../../redux/store";
import { open, setContent } from "../../redux/slices/modal";
import { StatusModal } from "../../components/StatusModal";
import { useSearchParams } from "react-router-dom";

const Home = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const status = searchParams.get("status") || "1";
    if (!parseInt(status)) {
      dispatch(setContent(<StatusModal />));
      dispatch(open());
    }
  }, [searchParams, dispatch]);

  return (
    <div>
      <PageWrapper>
        <Intro />
        <HomeText />
      </PageWrapper>
    </div>
  );
};

const HomeText = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <div className="image-content-home ">
              <img
                alt="certificado"
                className="img-fluid-new"
                src={certificado}
              />
            </div>
            <div className="text-content">
              <h1 className="text-center fw-bold">
                ¡La evaluación es gratuita!
              </h1>
              <p className="text-center text-intro">
                Aprovecha la oportunidad para evaluar tus competencias digitales
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <div className="image-content-home ">
              <img
                alt="perfil-digital"
                className="img-fluid-new"
                src={estudiante}
              />
            </div>
            <div className="text-content">
              <h1 className="text-center fw-bold">
                ¡Conoce tu perfil digital!
              </h1>
              <p className="text-center text-intro">
                Al finalizar la prueba obtendrás un reporte completo de tu
                perfil digital
              </p>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <div className="image-content-home ">
              <img
                alt="recomendaciones"
                className="img-fluid-new"
                src={recomendaciones}
              />
            </div>
            <div className="text-content">
              <h1 className="text-center fw-bold">
                ¡Recomendaciones personalizadas!
              </h1>
              <p className="text-center text-intro">
                Obtén propuestas de cursos y lleva tus competencias digitales al
                siguiente nivel
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <CardList />
      </div>
    </>
  );
};

export default Home;
